import React, { useState } from 'react'
import Tabs from 'src/components/_Dsc/Tabs'

import { Community } from 'src/pages/communities'
import PendingApprovalModal from '../PendingApprovalModal'
import { Content } from './components/Content'
import { Characters } from './components/Characters'
import { ChannelSettings } from './components/Settings'
import { Transmission } from './components/Transmission'

const options = [
    { label: 'Content', value: 'content' },
   
    { label: 'Settings', value: 'settings' },
    { label: 'Trasmission', value: 'p2p' },
   
]
/**
  {
      
       * { label: 'Characters', value: 'characters' },
       
    }
 */

interface Props {
    community_id: string
}

export const Channels: React.FC<Props> = ({ community_id }) => {
    const [isSelected, setIsSelected] = useState('content')
    const [handleUpdate, setHandleUpdate] = useState(false)
    const handleTabsChange = (val: string) => {
        setIsSelected(val)
    }
    return (
        <div className="bg-white p-4 flex flex-col gap-2">
            <Tabs options={options} value={isSelected} onChange={handleTabsChange} />

          {
            isSelected
            === 'content' && <Content community_id={community_id} />
            
          }
           {
            isSelected
            === 'characters' && <Characters community_id={community_id} />
            
          }
          {
            isSelected
            === 'settings' && <ChannelSettings community_id={community_id} />
            
          }
           {
            isSelected
            === 'p2p' && <Transmission community_id={community_id} />
            
          }
        </div>
    )
}
