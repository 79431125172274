import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Breadcrumb from 'src/components/_Dsc/Breadcrumb'
import Button from 'src/components/_Dsc/Button'
import { CardContainer, CardInner } from 'src/components/_Dsc/Card'
import DscIcons from 'src/components/_Dsc/icons'
import Modal from 'src/components/_Dsc/Modal'
import ImgCommunity from 'src/assets/kinera_communities.png'
import {
	TextStyleBodyDefault,
	TextStyleCaption,
	TextStyleH1,
	TextStyleH3,
	TextStyleLinkDefault,
} from 'src/components/_Dsc/typography'
import { Loading } from 'src/components/loading'
import KineContext from 'src/context/KineContext'

import { communityService } from 'src/service/community/communityService'
import { planksToTokens } from 'src/service/substrate/formated'
import UserBalance from 'src/components/balances'
import Tabs from 'src/components/_Dsc/Tabs'

const path = [
	{ label: 'Home', link: '/' },
	{ label: 'Communities', link: '' },
]

export interface CommunityType {
	monthlyFee: string
}

export interface Community {
	communityType: { [key: string]: CommunityType }
	createdAt: string
	proposalReason: string
	socialUserName: string
	createdBy: string
	description: string
	id: string
	members: string
	name: string
	status: string
	voteResult: string
	votesApprove: string
	votesReject: string
	votingPeriodEnd: string
	votingPeriodStart: string
	icon: string
	category: string
}

const categories = [
	{
		label: 'Communities',
		value: 'communities',
	},
	{
		label: 'My Communities',
		value: 'user_communitites',
	},
]

const Communities: React.FC = () => {
	const { user, apiState, setOnQueue, onQueue, profile, handleGlobalMessage } = React.useContext(KineContext)
	const [inFetch, setInFetch] = useState<boolean>(false)
	const [inFetchUsers, setInFetchUsers] = useState<boolean>(false)
	const [userExistInCommunity, setuserExistInCommunity] = useState<boolean>(false)
	const [userPendingRequest, setUserPendingRequest] = useState<boolean>(false)
	const [communities, setCommunities] = useState<Community[]>([])
	const [isSelected, setIsSelected] = useState('communities')
	const [selectedCommunity, setSelectedComunity] = useState<Community>({} as Community)
	const [showModal, setShowModal] = useState<boolean>(false)
	const [userCommunitites, setUserCommunities] = useState<Community[]>([])
	const [showUserCommunities, setShowUserCommunities] = useState<boolean>(false)

	const navigate = useNavigate()

	const getUserCommunities = async () => {
		setInFetch(true)
		let req = await communityService.getCommunitiesByUser(user.address, apiState)
		setUserCommunities(req)
		setInFetch(false)
		return req
	}

	const getUserCommunitiesRefresh = async () => {
		let req = await communityService.getCommunitiesByUser(user.address, apiState)
		setUserCommunities(req)

		return req
	}

	const getCommunitiesByType = async (type: string) => {
		setCommunities([])
		setInFetch(true)
		let req = await communityService.getCommunities(type, apiState)
		let userCommunities: Community[] = []
		if (user && user.address) {
			userCommunities = await getUserCommunities()
		} else {
			setInFetch(false)
		}

		if (userCommunities && userCommunities.length && userCommunities.length > 0) {
			const filteredReq = req.filter(
				(community: Community) =>
					!userCommunities.some((userCommunity: Community) => userCommunity.id === community.id),
			)

			setCommunities(filteredReq)
		} else {
			setCommunities(req)
		}
	}

	const changeTab = (val: string) => {
		setIsSelected(val)
		if (val === 'user_communitites') {
			setShowUserCommunities(true)
		} else {
			setShowUserCommunities(false)
		}
		//setCommunities([])
		//setInFetch(true)
		//setIsSelected(val)
		//getCommunitiesByType(val)
	}

	const handleChangeModal = async (community: Community, type: string) => {
		if (type === 'Public' || type === 'PublicDao') {
			//setInFetch(true)

			//setOnQueue(true)
			//await communityService.applyForCommunity(user, community, handleGlobalMessage)
			//await getUserCommunities()

			console.log(type)
			navigate(`/community/${community.id}`)
		} else {
			setShowModal(true)
			setSelectedComunity(community)
			setInFetchUsers(true)
			let req = await communityService.verifyUserExistInCommunity(user, community.id, apiState)
			let pendingVerify = await communityService.verifyPendingRequest(user, community.id, apiState)
			setuserExistInCommunity(req)
			setUserPendingRequest(pendingVerify)
			setInFetchUsers(false)
			//navigate(`/my-communities`)
		}
	}

	const closeModal = () => {
		setShowModal(false)
	}

	const applyUserForCommunity = async (community: Community) => {
		await communityService.applyForCommunity(user, community, handleGlobalMessage, apiState)
		setOnQueue(true)
		closeModal()
		setOnQueue(false)
	}

	const applyUserForCommunityMonthly = async (community: Community) => {
		setOnQueue(true)
		await communityService.applyForCommunityMonthly(user, community, apiState)
		closeModal()
		setOnQueue(false)
	}

	useEffect(() => {
		if (apiState) {
			getCommunitiesByType('Approve')
			if (user && user.address) {
				const interval = setInterval(() => {
					getUserCommunitiesRefresh()
				}, 5000) // 5000 ms = 5 segundos

				return () => clearInterval(interval)
			}
		}
	}, [user, apiState])

	return (
		<div className="page-padding">
			{selectedCommunity &&
			selectedCommunity.communityType &&
			Object.keys(selectedCommunity.communityType)[0] &&
			showModal ? (
				<Modal
					open={showModal}
					title={'Apply for Membership'}
					onConfirm={() => {
						Object.keys(selectedCommunity.communityType)[0] === 'Private'
							? applyUserForCommunityMonthly(selectedCommunity)
							: applyUserForCommunity(selectedCommunity)
					}}
					confirmDisabled={inFetch || inFetchUsers || onQueue}
					confirmLabel="Apply"
					onClose={closeModal}
					onCancel={closeModal}
					cancelLabel="Cancel"
				>
					<div className="relative w-full h-full">
						{inFetchUsers ? (
							<Loading />
						) : (
							<div>
								{userExistInCommunity ? (
									<TextStyleBodyDefault>You are already in this community</TextStyleBodyDefault>
								) : userPendingRequest ? (
									<TextStyleBodyDefault>
										You have already made the request to join the community, wait for approval
									</TextStyleBodyDefault>
								) : (
									<div className="w-full flex flex-col gap-2">
										{user && user.address ? (
											<div className="flex flex-col">
												<TextStyleBodyDefault>{user.name}</TextStyleBodyDefault>
												<TextStyleBodyDefault className="flex items-center gap-2">
													Your wallet: <UserBalance />
												</TextStyleBodyDefault>
											</div>
										) : (
											''
										)}
										<div className="community-body-img"></div>
										<div className="z-[100] h-[200px] justify-center  flex flex-col gap2">
											<TextStyleH1>{selectedCommunity.name}</TextStyleH1>
											<TextStyleBodyDefault>
												Type: {Object.keys(selectedCommunity.communityType)[0]} DAO
											</TextStyleBodyDefault>
											{Object.keys(selectedCommunity.communityType)[0] === 'Private' ? (
												planksToTokens(Object.values(selectedCommunity.communityType)[0].monthlyFee) > 0 ? (
													<TextStyleCaption>
														This community charges a membership fee of
														<b>
															{planksToTokens(Object.values(selectedCommunity.communityType)[0].monthlyFee)} - KinexPlay
														</b>
														<span className="ml-1">
															The fee will be deducted from your wallet once you approve the transaction.
														</span>
													</TextStyleCaption>
												) : (
													<TextStyleCaption>Membership of this community is free of charge.</TextStyleCaption>
												)
											) : (
												''
											)}
										</div>
									</div>
								)}
							</div>
						)}
					</div>
				</Modal>
			) : (
				''
			)}

			<Breadcrumb routes={path} />
			<CardInner>
				<CardContainer className="gap-2 flex flex-col">
					<div className="w-full flex flex-col">
						<div className="flex items-center justify-between">
							<TextStyleH1>Communities</TextStyleH1>
							{user && user.address ? (
								<Button
									value="Create Community"
									icon="add"
									size="small"
									onClick={() => {
										navigate('/community-proposal')
									}}
								/>
							) : (
								''
							)}
						</div>

						{/**
						 * <Tabs onChange={changeTab} options={categories} value={isSelected}></Tabs>
						 */}
					</div>
					{inFetch ? (
						<Loading />
					) : (
						<div className="mt-4 flex flex-col gap-2">
							{(communities && communities.length) || (showUserCommunities && userCommunitites.length) ? (
								showUserCommunities ? (
									userCommunitites.map((val: Community, index: number) => (
										<div
											key={index}
											className="bg-white p-4 flex flex-col w-full items-end justify-end gap-2 rounded-[4px]"
										>
											<div className=" flex  w-full justify-between">
												<div className="flex items-center gap-2">
													<img
														src={val.icon}
														width="60px"
														height="60px"
														className="rounded-full w-[60px] h-[60px] rounded-[100%]"
													/>
													<div className="flex flex-col gap-1">
														<TextStyleH3>{val.name}</TextStyleH3>
														<TextStyleBodyDefault>{val.description}</TextStyleBodyDefault>
													</div>
												</div>
												<div className="flex flex-col items-end gap-2">
													<div className="flex flex-col gap-1 items-end justify-end">
														<TextStyleCaption key={index}>
															{Object.keys(val.communityType)[0] === 'SemiPublicOrganisation'
																? 'Semi-Public Organisation'
																: Object.keys(val.communityType)[0] === 'PublicDao'
																? 'Public DAO'
																: Object.keys(val.communityType)[0] === 'Public'
																? 'Public'
																: `${Object.keys(val.communityType)[0]} DAO`}
														</TextStyleCaption>
														{Object.keys(val.communityType)[0] === 'Private' &&
														planksToTokens(Object.values(val.communityType)[0].monthlyFee) > 0 ? (
															<div className="flex items-center gap-1">
																<span className="material-symbols-outlined">payments</span>
																<TextStyleCaption>
																	{planksToTokens(Object.values(val.communityType)[0].monthlyFee)} - KinexPlay
																</TextStyleCaption>
															</div>
														) : (
															''
														)}
													</div>
													<div className="flex items-center gap-1">
														<TextStyleBodyDefault>{val.members}</TextStyleBodyDefault>
														<DscIcons icon="group" />
													</div>
												</div>
											</div>
											{profile && isSelected === 'communities' && user.address !== val.createdBy ? (
												<div>
													<div className="flex items-center gap-2">
														<Button
															value={
																Object.keys(val.communityType)[0] === 'Public' ||
																Object.keys(val.communityType)[0] === 'PublicDao'
																	? 'View community'
																	: 'Become a member'
															}
															icon={
																Object.keys(val.communityType)[0] === 'Public' ||
																Object.keys(val.communityType)[0] === 'PublicDao'
																	? 'visibility'
																	: 'login'
															}
															size="small"
															onClick={() => {
																handleChangeModal(val, Object.keys(val.communityType)[0])
															}}
														/>
														<Button
															value="Entry"
															icon="visibility"
															size="small"
															onClick={() => {
																navigate(`/community/${val.id}`)
															}}
														/>
													</div>
												</div>
											) : (
												<Button
													value="Entry"
													icon="visibility"
													size="small"
													onClick={() => {
														navigate(`/community/${val.id}`)
													}}
												/>
											)}
										</div>
									))
								) : (
									communities.map((val: Community, index: number) => (
										<div
											key={index}
											className="bg-white p-4 flex flex-col w-full items-end justify-end gap-2 rounded-[4px]"
										>
											<div className=" flex  w-full justify-between">
												<div className="flex items-center gap-2">
													<img
														src={val.icon}
														width="60px"
														height="60px"
														className="rounded-full w-[60px] h-[60px] rounded-[100%]"
													/>
													<div className="flex flex-col gap-1">
														<TextStyleH3>{val.name}</TextStyleH3>
														<TextStyleBodyDefault>{val.description}</TextStyleBodyDefault>
													</div>
												</div>
												<div className="flex flex-col items-end gap-2">
													<div className="flex flex-col gap-1 items-end justify-end">
														<TextStyleCaption key={index}>
															{Object.keys(val.communityType)[0] === 'SemiPublicOrganisation'
																? 'Semi-Public Organisation'
																: Object.keys(val.communityType)[0] === 'PublicDao'
																? 'Public DAO'
																: Object.keys(val.communityType)[0] === 'Public'
																? 'Public'
																: `${Object.keys(val.communityType)[0]} DAO`}
														</TextStyleCaption>
														{Object.keys(val.communityType)[0] === 'Private' &&
														planksToTokens(Object.values(val.communityType)[0].monthlyFee) > 0 ? (
															<div className="flex items-center gap-1">
																<span className="material-symbols-outlined">payments</span>
																<TextStyleCaption>
																	{planksToTokens(Object.values(val.communityType)[0].monthlyFee)} - KinexPlay
																</TextStyleCaption>
															</div>
														) : (
															''
														)}
													</div>
													<div className="flex items-center gap-1">
														<TextStyleBodyDefault>{val.members}</TextStyleBodyDefault>
														<DscIcons icon="group" />
													</div>
												</div>
											</div>
											<div className="flex items-center gap-2">
												<Button
													value={
														Object.keys(val.communityType)[0] === 'Public' ||
														Object.keys(val.communityType)[0] === 'PublicDao'
															? 'View community'
															: 'Become a member'
													}
													icon={
														Object.keys(val.communityType)[0] === 'Public' ||
														Object.keys(val.communityType)[0] === 'PublicDao'
															? 'visibility'
															: 'login'
													}
													size="small"
													onClick={() => {
														handleChangeModal(val, Object.keys(val.communityType)[0])
													}}
												/>
												<Button
													value="Description"
													type="subtle"
													icon="visibility"
													size="small"
													onClick={() => {
														navigate(`/community/proposal/${val.id}`)
													}}
												/>
											</div>
										</div>
									))
								)
							) : (
								<p>No data to display</p>
							)}
						</div>
					)}
				</CardContainer>
			</CardInner>
		</div>
	)
}

export default Communities
