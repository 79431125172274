import React, { useEffect, useState } from 'react'
import { useHMSActions, useHMSStore, selectPeers, selectIsLocalAudioEnabled } from '@100mslive/react-sdk'
import axios from 'axios'
import Peers from './components/Peers'
import Controls from './components/PeersControl'
import KineContext from 'src/context/KineContext'

export interface UserVoice {
	id: string
	name: string
	img: string
	isMicEnabled: boolean
}

interface Props {
	user: UserVoice
	tab: string
	setOnlineMembersVoice: (voice: UserVoice[]) => void
	setIsSelectedTimeline: (param: string) => void
}

const VoiceChannel: React.FC<Props> = ({ tab, setIsSelectedTimeline }) => {
	const { user } = React.useContext(KineContext)
	const [loading, setLoading] = useState<boolean>(false)
	const [broadcasterCode, setBroadcasterCode] = useState<string | null>(null)
	const hmsActions = useHMSActions()
	const peers = useHMSStore(selectPeers)
	const isLocalAudioEnabled = useHMSStore(selectIsLocalAudioEnabled)
	const communityId = window.location.pathname.split('/').pop() || 'default'

	const createOrFetchRoom = async () => {
		try {
			setLoading(true)

			const payload = {
				name: `community-${communityId}`,
				description: `Voice chat room for community ${communityId}`,
				template_id: '66b83b359928c864eafbfcfc',
			}

			// Attempt to create the room
			const roomResponse = await axios.post('https://api.100ms.live/v2/rooms', payload, {
				headers: {
					Authorization: `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE3NDIxNjc3NzUsImV4cCI6MTc0MzM3NzM3NSwianRpIjoiMjA3ZjhlN2UtMTc5Mi00NWI1LTk2N2EtOGFhODFjNWFjZTNmIiwidHlwZSI6Im1hbmFnZW1lbnQiLCJ2ZXJzaW9uIjoyLCJuYmYiOjE3NDIxNjc3NzUsImFjY2Vzc19rZXkiOiI2Njk0MTQ2NmFkZDEyNjUxYTAyZjg5YzgifQ.WK6Tcpb-5Qvwwx1uoOucWT-GJJWAAkz65XOZ_bTqowc`,
				},
			})

			const roomId = roomResponse.data.id

			// Fetch the broadcaster room code
			const roomCodeResponse = await axios.post(
				`https://api.100ms.live/v2/room-codes/room/${roomId}`,
				{},
				{
					headers: {
						Authorization: `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE3NDIxNjc3NzUsImV4cCI6MTc0MzM3NzM3NSwianRpIjoiMjA3ZjhlN2UtMTc5Mi00NWI1LTk2N2EtOGFhODFjNWFjZTNmIiwidHlwZSI6Im1hbmFnZW1lbnQiLCJ2ZXJzaW9uIjoyLCJuYmYiOjE3NDIxNjc3NzUsImFjY2Vzc19rZXkiOiI2Njk0MTQ2NmFkZDEyNjUxYTAyZjg5YzgifQ.WK6Tcpb-5Qvwwx1uoOucWT-GJJWAAkz65XOZ_bTqowc`,
						'Content-Type': 'application/json',
					},
				},
			)

			const roomCodeData = roomCodeResponse.data.data
			const code = roomCodeData.find((item: { role: string }) => item.role === 'broadcaster')?.code

			if (code) {
				setBroadcasterCode(code)
				joinConference(code)
			} else {
				console.error('Broadcaster Room Code not found.')
			}
		} catch (error: any) {
			if (error.response?.status === 409) {
				// Room already exists, fetch it
				const existingRoomResponse = await axios.get(`https://api.100ms.live/v2/rooms?name=community-${communityId}`, {
					headers: {
						Authorization: `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE3NDIxNjc3NzUsImV4cCI6MTc0MzM3NzM3NSwianRpIjoiMjA3ZjhlN2UtMTc5Mi00NWI1LTk2N2EtOGFhODFjNWFjZTNmIiwidHlwZSI6Im1hbmFnZW1lbnQiLCJ2ZXJzaW9uIjoyLCJuYmYiOjE3NDIxNjc3NzUsImFjY2Vzc19rZXkiOiI2Njk0MTQ2NmFkZDEyNjUxYTAyZjg5YzgifQ.WK6Tcpb-5Qvwwx1uoOucWT-GJJWAAkz65XOZ_bTqowc`,
					},
				})

				const roomId = existingRoomResponse.data.rooms[0].id

				// Fetch the broadcaster room code
				const roomCodeResponse = await axios.post(
					`https://api.100ms.live/v2/room-codes/room/${roomId}`,
					{},
					{
						headers: {
							Authorization: `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE3NDIxNjc3NzUsImV4cCI6MTc0MzM3NzM3NSwianRpIjoiMjA3ZjhlN2UtMTc5Mi00NWI1LTk2N2EtOGFhODFjNWFjZTNmIiwidHlwZSI6Im1hbmFnZW1lbnQiLCJ2ZXJzaW9uIjoyLCJuYmYiOjE3NDIxNjc3NzUsImFjY2Vzc19rZXkiOiI2Njk0MTQ2NmFkZDEyNjUxYTAyZjg5YzgifQ.WK6Tcpb-5Qvwwx1uoOucWT-GJJWAAkz65XOZ_bTqowc`,
							'Content-Type': 'application/json',
						},
					},
				)

				const roomCodeData = roomCodeResponse.data.data
				const code = roomCodeData.find((item: { role: string }) => item.role === 'broadcaster')?.code

				if (code) {
					setBroadcasterCode(code)
					await handleUserCheckAndJoin(code)
				} else {
					console.error('Broadcaster Room Code not found.')
				}
			} else {
				console.error('Error creating or fetching room:', error)
			}
		} finally {
			setLoading(false)
		}
	}

	const handleUserCheckAndJoin = async (code: string) => {
		const existingPeer = peers.find(peer => peer.name === user.name)
		if (existingPeer) {
			console.log('User already exists in the room. Leaving first...')
			await leaveRoom()
		}
		await joinConference(code)
	}

	const leaveRoom = async () => {
		try {
			await hmsActions.leave()

			console.log('tab', tab)
		} catch (error) {
			console.error('Failed to leave the room:', error)
		}
	}

	const joinConference = async (code: string) => {
		try {
			const authToken = await hmsActions.getAuthTokenByRoomCode({
				roomCode: code,
			})
			if (user && user.address) {
				await hmsActions.join({
					settings: {
						isAudioMuted: true,
						isVideoMuted: true,
					},
					userName: user.name,
					authToken,
					metaData: JSON.stringify({
						image: user.img,
					}),
				})
			}
		} catch (error) {
			console.error('Error joining the conference:', error)
		}
	}

	useEffect(() => {
		createOrFetchRoom()
	}, [user])

	const toggleMic = () => {
		hmsActions.setLocalAudioEnabled(!isLocalAudioEnabled)
	}

	return (
		<div className="flex flex-col gap-4">
			{tab === 'Posts' ? (
				<div>
					<h2>Voice Channel - Community {communityId}</h2>
					{loading ? (
						<p>Loading...</p>
					) : (
						<div>
							<div>
								<h3>Connected Users:</h3>
								<div className="flex flex-col gap-2">
									{peers.map(peer => (
										<Peers key={peer.id} peer={peer} />
									))}
								</div>
							</div>
						</div>
					)}
				</div>
			) : (
				''
			)}
		</div>
	)
}

export default VoiceChannel
