import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Button from 'src/components/_Dsc/Button'
import InitialSimbol from 'src/components/_Dsc/InitialSimbol'
import { TextStyleBodyDefault } from 'src/components/_Dsc/typography'
import { Loading } from 'src/components/loading'
import KineContext from 'src/context/KineContext'
import { Community } from 'src/pages/communities'
import { communityService } from 'src/service/community/communityService'

interface Member {
	communityId: string
	communityName: string
	user: string
	socialName: string
}

interface Props {
	isGovernance: boolean
	communityDetails: Community
}

const Members: React.FC<Props> = ({ isGovernance, communityDetails }) => {
	const { user, apiState } = useContext(KineContext)
	const [members, setMembers] = useState([] as Member[])
	const [inFetch, setInFetch] = useState(true)
	const navigate = useNavigate()
	const getCurrentMembers = async () => {
		setInFetch(true)
		let req = await communityService.getAllMembersInCommunity(window.location.pathname.split('/')[2], apiState)
		console.log('communityDetails', communityDetails)
		setMembers(req)
		setInFetch(false)
	}
	const redirectToProfile = (user: string) => {
		navigate(`/social/view/${user}`)
	}
	useEffect(() => {
		if (apiState) {
			getCurrentMembers()
		}
	}, [apiState])
	return (
		<div>
			{inFetch ? (
				<Loading />
			) : (
				<div className="flex flex-col gap-1 ">
					{members && members.length
						? members.map((member: Member, index: number) => (
								<div key={index} className="bg-white rounded-[4px] p-2 flex items-center gap-2 justify-between border ">
									<div className="flex items-center gap-2">
										<InitialSimbol profile={{ img: undefined, name: member.socialName }} />
										<TextStyleBodyDefault>{member.socialName}</TextStyleBodyDefault>
									</div>

									<div className="flex items-center gap-4">
										{member.user === communityDetails.createdBy ? (
											<div className="flex items-center cursor-default">
												<Button iconOnly value="Founder" size='small' icon="background_replace" type="subtle" />
												<TextStyleBodyDefault>Founder</TextStyleBodyDefault>
											</div>
										) : (
											''
										)}
										{user && user.address && user.address !== member.user && isGovernance ? (
											<Button value="Remove member" size="small" icon="delete" type="critical" />
										) : (
											''
										)}
										<Button
											size="small"
											value="View profile"
											onClick={() => {
												redirectToProfile(member.user)
											}}
										/>
									</div>
								</div>
						  ))
						: 'No data to display'}
				</div>
			)}
		</div>
	)
}
export default Members
