import React from 'react'
import { DsyIconButton, DsyIconButtonContent } from './styles'
import { IIconButtonProps } from './interface'

export const IconButton: React.FC<IIconButtonProps> = ({
	size = 'default',
	icon,
	tooltip = '',
	outlined = false,
	selected = false,
	disabled = false,
	onClick,
	color = '',
}) => {
	return (
		<DsyIconButton onClick={onClick}>
			<DsyIconButtonContent size={size} disabled={disabled} selected={selected}>
				{outlined ? (
					<i className={`material-symbols-outlined ${color}`}>{icon}</i>
				) : (
					<i className={`material-symbols-outlined ${color}`}>{icon}</i>
				)}
			</DsyIconButtonContent>
		</DsyIconButton>
	)
}

export default IconButton
