import React, { useState } from 'react'
import Tabs from 'src/components/_Dsc/Tabs'
import Members from './Members'
import { Settings } from './Settings'
import { Community } from 'src/pages/communities'
import PendingApprovalModal from '../PendingApprovalModal'

const options = [
	{ label: 'Members', value: 'Members' },
	{ label: 'Settings', value: 'Settings' },
	{ label: 'Pending Approval', value: 'approval' },
]

interface Props {
	communityDetails: Community
	setOpen: (param: boolean) => void
	open: boolean
}

export const CommunityGovernance: React.FC<Props> = ({ communityDetails, setOpen, open }) => {
	const [isSelected, setIsSelected] = useState('Members')
	const handleTabsChange = (val: string) => {
		setIsSelected(val)
	}
	return (
		<div className="bg-white p-4 flex flex-col gap-2">
			<Tabs options={options} value={isSelected} onChange={handleTabsChange} />

			<div>
				{isSelected === 'Members' && <Members isGovernance communityDetails={communityDetails}/>}
				{isSelected === 'Settings' && <Settings communityDetails={communityDetails} />}
				{isSelected === 'approval' && <PendingApprovalModal community={communityDetails} />}
			</div>
		</div>
	)
}
