import React from "react";

interface Props {
    community_id: string
}

export const ChannelSettings:React.FC<Props> = ({community_id}) => {
    return (
        <div className="bg-white p-4 flex flex-col gap-2">
            Settings
        </div>
    )
}