import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Breadcrumb from 'src/components/_Dsc/Breadcrumb'
import { CardContainer, CardInner } from 'src/components/_Dsc/Card'
import { TextStyleBodyMedium, TextStyleCaption, TextStyleH1 } from 'src/components/_Dsc/typography'
import { getPublicTransmissions } from 'src/service/community/communityService'
import { ITransmission } from '../community/components/Channels/components/Transmission'
import { Loading } from 'src/components/loading'
import Button from 'src/components/_Dsc/Button'

interface IEnhancedTransmission extends ITransmission {
	extension: string
	url: string
	community_name: string
}

export const ComunityChannels: React.FC = () => {
	const [inFetch, setInFetch] = React.useState<boolean>(true)
	const [transmissions, setTransmissions] = useState<IEnhancedTransmission[]>([])

	const navigate = useNavigate()
	const getAllPublicTransmissions = async () => {
		setInFetch(true)
		let req = await getPublicTransmissions()
		if (req && req.transmissions) {
			console.log(req.transmissions)
			setTransmissions(req.transmissions)
		}
		setInFetch(false)
	}

	useEffect(() => {
		getAllPublicTransmissions()
	}, [])
	return (
		<div className="w-full flex flex-col h-full page-padding">
			<CardInner className="flex flex-col h-full">
				<CardContainer className="gap-2 flex flex-col h-full">
					<div className="w-full flex flex-col h-full">
						<div className="flex flex-col">
							<TextStyleH1>Transmissions</TextStyleH1>
							<TextStyleCaption></TextStyleCaption>
						</div>
						<div className="flex gap-2 flex-wrap bg-white p-2">
							{inFetch ? (
								<Loading />
							) : transmissions && transmissions.length ? (
								<div className="flex flex-col gap-2 w-full">
									{transmissions.map((transmission: IEnhancedTransmission, key: number) => (
										<div 
										onClick={() => {navigate(`/channel/${transmission.channel_id}/transmission/${transmission.id}`)}}
										className="p-2 border border-2 border-[#ededed] w-full cursor-pointer hover:bg-gray-100 hover:border-[#0053e4]" key={key}>
											<div className="flex items-center justify-between">
												<div className="flex items-center gap-2">
													<Button iconOnly type="subtle" size="small" icon="podcasts" value="podcasts" />
													<TextStyleBodyMedium>{transmission.transmission_name}</TextStyleBodyMedium>
												</div>
												<div className="flex items-center gap-2">
													<TextStyleCaption className="capitalize ">
														<b>
															{transmission.init_time} - {transmission.end_time}
														</b>
													</TextStyleCaption>
													<TextStyleCaption className="capitalize ">{transmission.extension}</TextStyleCaption>
													<Button
														iconOnly
														type="subtle"
														size="small"
														icon={
															transmission.extension === 'video'
																? 'play_circle'
																: transmission.extension === 'img'
																? 'image'
																: 'psychology'
														}
														value={
															transmission.extension === 'video'
																? 'video'
																: transmission.extension === 'img'
																? 'image'
																: 'script'
														}
													/>
												</div>
											</div>
										</div>
									))}
								</div>
							) : (
								<TextStyleCaption>No active broadcasts at the moment</TextStyleCaption>
							)}
						</div>
					</div>
				</CardContainer>
			</CardInner>
		</div>
	)
}
