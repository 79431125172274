import React, { useState } from 'react'
import { CardContainer, CardInner } from 'src/components/_Dsc/Card'
import Modal from 'src/components/_Dsc/Modal'
import { Community } from 'src/pages/communities'
import '../styles.scss'
import { TextStyleBodyDefault } from 'src/components/_Dsc/typography'
import { communityService } from 'src/service/community/communityService'
import KineContext from 'src/context/KineContext'

interface Props {
	open: boolean
	setOpen(value: boolean): void
	community: Community
}

const options = [
	{ label: 'Approve', value: true },
	{ label: 'Reject', value: false },
]

const CastCommunityVote: React.FC<Props> = ({ open, setOpen, community }) => {
	const { user, setOnQueue, handleGlobalMessage, apiState } = React.useContext(KineContext)
	const [selectedOption, setSelectedOption] = useState(true)
	const [inFetch, setInFetch] = useState(false)
	const handleClose = () => {
		setOpen(false)
	}
	const handleConfirm = async () => {
		if (user && user.address) {
			setInFetch(true)
			await communityService.submiteCommunityVote(
				user,
				community.id,
				selectedOption,
				setOnQueue,
				community,
				handleGlobalMessage,
				apiState,
			)
			setInFetch(false)
			handleClose()
		}
	}
	const handleClick = (option: boolean) => {
		setSelectedOption(option)
	}

	return (
		<Modal
			title="Cast community vote"
			onConfirm={handleConfirm}
			cancelLabel="Cancel"
			confirmLabel="Vote"
			open={open}
			onCancel={handleClose}
			onClose={handleClose}
			confirmDisabled={inFetch}
		>
			<div className="flex flex-col w-full">
				<TextStyleBodyDefault>Chose your vote</TextStyleBodyDefault>
				<CardInner className="votes-container">
					<div className=" flex items-center gap-2">
						{options.map((val: any, index: number) => (
							<div
								onClick={() => {
									handleClick(val.value)
								}}
								key={index}
								className={` ${
									selectedOption === val.value ? val.label : 'not-selected'
								} cursor-pointer hover:opacity-90`}
							>
								{val.label}
							</div>
						))}
					</div>
				</CardInner>
			</div>
		</Modal>
	)
}

export default CastCommunityVote
