import React, { useContext, useEffect, useState } from 'react'
import { formatDate } from 'src/_helpers'
import Button from 'src/components/_Dsc/Button'
import DscInput from 'src/components/_Dsc/input'
import InputText from 'src/components/_Dsc/InputText'
import Modal from 'src/components/_Dsc/Modal'
import { TextStyleBodyDefaultMedium, TextStyleCaption } from 'src/components/_Dsc/typography'
import { Loading } from 'src/components/loading'
import KineContext from 'src/context/KineContext'
import { getChannelFiles, insertUserFileComunity, removeUserFile } from 'src/service/storage/Ipfs'

export interface ChannelContent {
	community_id: number
	created_at: string
	custom_name: string
	document_id: null | number
	document_name: string
	extension: string
	file_hash: string
	file_name: string
	file_size: string
	id: number
	owner_name: string
	url: string
	user_address: string
}

interface Props {
	community_id: string
}

export const Content: React.FC<Props> = ({ community_id }) => {
	const { user, setOnQueue, handleGlobalMessage } = useContext(KineContext)
	const [showModal, setShowModal] = useState(false)
	const [showRemoveModal, setShowRemoveModal] = useState(false)
	const [isFetch, setIsFetch] = useState(true)
	const [type, setType] = useState('')
	const [removeFile, setRemoveFile] = useState<ChannelContent>({} as ChannelContent)
	const [selectedFile, setSelectedFile] = useState<File | null>(null)
	const [inInsertFile, setInsertFile] = useState(false)
	const [files, setFiles] = useState<ChannelContent[]>([])
	const [customName, setCustomName] = useState('')

	const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.files && event.target.files[0]) {
			setSelectedFile(event.target.files[0])
		}
	}

	const getCurrentChannelFiles = async () => {
		setIsFetch(true)
		let req = await getChannelFiles(community_id)
		if (req && req.length) {
			setFiles(req)
		}
		setIsFetch(false)
	}

	const handleInsertFile = async () => {
		if (selectedFile && community_id) {
			setInsertFile(true)

			await insertUserFileComunity(selectedFile, user.address, 'channel', community_id, type, customName, 'channel')
			setOnQueue(true)
			handleGlobalMessage('Your file is being processed and will soon be available')
			//await getAllFilesCommunity()
			await getCurrentChannelFiles()
			setShowModal(false)
			setInsertFile(false)
		}
	}

	const handleRemoveFile = async (file: ChannelContent) => {
		setOnQueue(true)
		setInsertFile(true)
		setIsFetch(true)
		handleGlobalMessage('Your order is being processed and the file will soon be deleted.')
		await removeUserFile(user.address, file.file_hash)

		let currentFiles: ChannelContent[] = files.filter((val: ChannelContent) => val.file_hash !== file.file_hash)
		console.log('currentFiles', currentFiles)
		setFiles(currentFiles)
		setInsertFile(false)
		setShowModal(false)
		setIsFetch(false)
		setShowRemoveModal(false)
	}

	const handleShowRemoveModal = (file: ChannelContent) => {
		setShowRemoveModal(true)
		setRemoveFile(file)
	}

	useEffect(() => {
		getCurrentChannelFiles()
	}, [])

	return (
		<div className="bg-white p-4 flex flex-col gap-2 w-full">
			{showRemoveModal ? (
				<Modal
					open={showRemoveModal}
					title={'Remove Content'}
					onConfirm={() => {
						handleRemoveFile(removeFile)
					}}
					confirmDisabled={inInsertFile}
					type="critical"
					confirmLabel="Confirm"
					cancelLabel="Cancel"
					onCancel={() => {
						setShowRemoveModal(false)
					}}
					onClose={() => {
						setShowRemoveModal(false)
					}}
					size="large">
					<div className="flex flex-col w-full gap-2">
						<div className="flex flex-col gap-2">
							<TextStyleBodyDefaultMedium>
								Please note that by confirming this action, this content and any existing configuration linked to it in
								the broadcast will be removed.
							</TextStyleBodyDefaultMedium>
							<div className="border p-2 border-2 border-[#ededed] w-full">
								<div className="flex w-full justify-between items-center">
									<TextStyleBodyDefaultMedium>
										{removeFile.custom_name ?? removeFile.file_name}
									</TextStyleBodyDefaultMedium>
								</div>
							</div>
						</div>
					</div>
				</Modal>
			) : (
				''
			)}
			{showModal ? (
				<Modal
					confirmDisabled={!selectedFile || inInsertFile}
					open={showModal}
					title={'Add Content'}
					onConfirm={() => {
						handleInsertFile()
					}}
					confirmLabel="Confirm"
					cancelLabel="Cancel"
					onCancel={() => {
						setShowModal(false)
					}}
					onClose={() => {
						setShowModal(false)
					}}
					size="large">
					<div className="flex flex-col w-full gap-2">
						<div className="flex flex-col gap-2">
							<TextStyleBodyDefaultMedium>Select Content type:</TextStyleBodyDefaultMedium>
							<div className="flex items-center gap-2">
								<div className="flex items-center">
									<input
										id="radio-image"
										type="radio"
										value="img"
										name="file-type"
										className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500"
										onChange={() => setType('img')}
									/>
									<label htmlFor="radio-image" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
										Image
									</label>
								</div>
								<div className="flex items-center">
									<input
										id="radio-video"
										type="radio"
										value="video"
										name="file-type"
										className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500"
										onChange={() => setType('video')}
									/>
									<label htmlFor="radio-video" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
										Video
									</label>
								</div>
								<div className="flex items-center">
									<input
										id="radio-file"
										type="radio"
										value="file"
										name="file-type"
										className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500"
										onChange={() => setType('file')}
									/>
									<label htmlFor="radio-file" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
										Reading (TXT, PDF)
									</label>
								</div>
							</div>
							{type && (
								<div className="flex items-center w-full justify-center gap-2">
									<Button
										value="Select File"
										size="large"
										onClick={() => document.getElementById('file-input')?.click()}
									/>
									<input
										id="file-input"
										type="file"
										accept={type === 'img' ? 'image/*' : type === 'video' ? 'video/*' : '.pdf, .txt'}
										className="hidden"
										onChange={handleFileChange}
									/>
								</div>
							)}
							{selectedFile && (
								<div className="mt-2 text-sm text-gray-700">
									<TextStyleCaption>Selected File: {selectedFile.name}</TextStyleCaption>
									<TextStyleCaption>Type: {selectedFile.type || 'Unknown type'}</TextStyleCaption>
								</div>
							)}
						</div>
						<div className="flex flex-col gap-2">
							<TextStyleBodyDefaultMedium>Select Content name:</TextStyleBodyDefaultMedium>
							<InputText
								disabled={!type || !selectedFile}
								placeholder="Content name"
								value={customName}
								maxLength={50}
								onChange={e => {
									setCustomName(e)
								}}
							/>
						</div>
					</div>
				</Modal>
			) : (
				''
			)}
			<div className="w-full flex justify-end items-end">
				<Button
					value="New Content"
					icon="add"
					size="small"
					onClick={() => {
						setShowModal(true)
					}}
				/>
			</div>
			<div className="flex flex-col items-start w-full gap-2">
				{isFetch ? (
					<Loading />
				) : files && files.length ? (
					files.map((file: ChannelContent, key: number) => (
						<div className="border p-2 border-2 border-[#ededed] w-full">
							<div className="flex w-full justify-between items-center">
								<div className="flex items-center gap-2">
									<TextStyleBodyDefaultMedium>{file.custom_name ?? file.file_name}</TextStyleBodyDefaultMedium>
									<TextStyleCaption>Type: {file.extension}</TextStyleCaption>
									<TextStyleCaption>Size: {file.file_size}</TextStyleCaption>
									<TextStyleCaption>Date: {formatDate(file.created_at)}</TextStyleCaption>
								</div>
								<Button
									iconOnly
									icon="delete"
									type="critical"
									value="Delete"
									size="small"
									onClick={() => {
										handleShowRemoveModal(file)
									}}
								/>
							</div>
						</div>
					))
				) : (
					<TextStyleCaption>This channel still has no content to display</TextStyleCaption>
				)}
			</div>
		</div>
	)
}
