import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Breadcrumb from 'src/components/_Dsc/Breadcrumb'
import { CardContainer, CardInner } from 'src/components/_Dsc/Card'
import { TextStyleBodyDefault, TextStyleCaption, TextStyleH1 } from 'src/components/_Dsc/typography'
import { Character } from '../community/components/Bazzar'
import { getPublicCharacters } from 'src/service/community/communityService'
import { Loading } from 'src/components/loading'
import Tabs from 'src/components/_Dsc/Tabs'
import { Characters } from './BazzarPreview'
import { ComunityChannels } from './Channels'

const path = [
	{ label: 'Home', link: '/' },
	{ label: 'Bazaar Preview', link: '' },
]

const options = [
	{ label: 'Characters', value: 'characters' },
	{ label: 'Transmissions', value: 'channels' },
]

export const BazzarPreview: React.FC = () => {
	const [publiCharacters, setPublicCharacters] = useState<Character[]>([])
	const [inFetch, setInFetch] = React.useState<boolean>(true)
	const [isSelected, setIsSelected] = useState('characters')

	const handleTabsChange = (val: string) => {
		setIsSelected(val)
	}

	const navigate = useNavigate()

	const getAllCharacters = async () => {
		setInFetch(true)
		let req = await getPublicCharacters()
		if (req && req.data) {
			setPublicCharacters(req.data)
		}
		setInFetch(false)
	}

	useEffect(() => {
		getAllCharacters()
	}, [])

	return (
		<div className="page-padding w-full h-full">
			<Tabs options={options} value={isSelected} onChange={handleTabsChange} />
						{
							isSelected === 'characters'
							? <Characters />
							: <ComunityChannels />
						}
		</div>
	)
}
