import React, { useEffect, useState } from 'react'
import Button from 'src/components/_Dsc/Button'
import { DsyLabel } from 'src/components/_Dsc/InputText/styles'
import Modal from 'src/components/_Dsc/Modal'
import { ChannelContent } from './Content'
import { getChannelFiles } from 'src/service/storage/Ipfs'
import { Loading } from 'src/components/loading'
import { TextStyleBodyDefaultMedium, TextStyleCaption, TextStyleH2 } from 'src/components/_Dsc/typography'
import { formatDate } from 'src/_helpers'
import { createNewChannelTransmission, getAllChannelTransmissions } from 'src/service/community/communityService'
import KineContext from 'src/context/KineContext'
import InputText from 'src/components/_Dsc/InputText'

interface Props {
	community_id: string
}

export interface ITransmission {
	id: string
	channel_id: string
	content_id: string
	user_id: string
	init_time: string
	end_time: string
	date: string
	end_date: string
	created_at: string
	transmission_name: string
}

export const Transmission: React.FC<Props> = ({ community_id }) => {
	const { user, setOnQueue, handleGlobalMessage } = React.useContext(KineContext)
	const [showModal, setShowModal] = useState(false)
	const [isFetch, setIsFetch] = useState(true)
	const [inFetch, setInFetch] = useState(true)
	const [files, setFiles] = useState<ChannelContent[]>([])
	const [selectedFile, setSelectedFile] = useState<ChannelContent>({} as ChannelContent)
	const [transmissions, setTransmissions] = useState<ITransmission[]>([])
	const [selectedDate, setSelectedDate] = useState('')
    const [endDate, setEndDate] = useState('')
	const [selectedTime, setSelectedTime] = useState('')
	const [endSelectedTime, setEndSelectedTime] = useState('')
	const [programName, setProgramName] = useState('')
	const handleCreateNewTransmission = async () => {
		setOnQueue(true)
		await createNewChannelTransmission(
			selectedFile.id,
			user.id,
			selectedTime,
			endSelectedTime,
			selectedDate,
			community_id,
			programName,
            endDate
		)
		setShowModal(false)
		await getTransmissions()
		handleGlobalMessage('Your order is being processed and the file will soon be deleted.')
	}

	const getTransmissions = async () => {
		setInFetch(true)
		let req = await getAllChannelTransmissions(community_id)
		console.log('rweq.data', req)
		if (req && req.data && req.data) {
			console.log('rweq.data', req.data)
			setTransmissions(req.data)
		}
		setInFetch(false)
	}

	const getCurrentChannelFiles = async () => {
		setIsFetch(true)
		let req = await getChannelFiles(community_id)
		if (req && req.length) {
			setFiles(req)
		}
		setIsFetch(false)
	}

	const handleSelectedFile = (file: ChannelContent) => {
		setSelectedFile(file)
	}

	useEffect(() => {
		getCurrentChannelFiles()
		setSelectedFile({} as ChannelContent)
		getTransmissions()
	}, [])

	return (
		<div className="bg-white p-4 flex flex-col gap-2">
			{showModal ? (
				<Modal
					confirmDisabled={
						!files ||
						!files.length ||
						!programName ||
						!selectedFile ||
						!selectedDate ||
						!selectedTime ||
                        !endDate ||
						!endSelectedTime
					}
					open={showModal}
					onConfirm={handleCreateNewTransmission}
					confirmLabel="Create"
					cancelLabel="Cancel"
					onCancel={() => setShowModal(false)}
					onClose={() => setShowModal(false)}
					title={'Add new content transmission'}>
					<div className="flex flex-col gap-2">
						<div className="flex items-center gap-4">
							<div className="flex flex-col items-start gap-1">
								<DsyLabel>Date</DsyLabel>
								<input
									type="date"
									className={`border p-1 ${selectedDate ? 'border-[#0053e4] border-2' : 'border-[#ededed]'}`}
									value={selectedDate}
									onChange={e => setSelectedDate(e.target.value)}
								/>
							</div>
							<div className="flex flex-col items-start gap-1">
								<DsyLabel>Init Time</DsyLabel>
								<input
									type="time"
									className={`border p-1 ${selectedTime ? 'border-[#0053e4] border-2' : 'border-[#ededed]'}`}
									value={selectedTime}
									onChange={e => setSelectedTime(e.target.value)}
								/>
							</div>
                            <div className="flex flex-col items-start gap-1">
								<DsyLabel>End Date</DsyLabel>
								<input
									type="date"
									className={`border p-1 ${endDate ? 'border-[#0053e4] border-2' : 'border-[#ededed]'}`}
									value={endDate}
									onChange={e => setEndDate(e.target.value)}
								/>
							</div>
							<div className="flex flex-col items-start gap-1">
								<DsyLabel>End Time</DsyLabel>
								<input
									type="time"
									className={`border p-1 ${endSelectedTime ? 'border-[#0053e4] border-2' : 'border-[#ededed]'}`}
									value={endSelectedTime}
									onChange={e => setEndSelectedTime(e.target.value)}
								/>
							</div>
						</div>
						<div>
							<InputText
								value={programName}
								onChange={setProgramName}
								placeholder="write program name"
								label="Program name"
								required
							/>
						</div>
						{isFetch ? (
							<Loading />
						) : files && files.length ? (
							<div className="flex flex-col gap-1">
								<DsyLabel>Select Content</DsyLabel>
								{files.map((file: ChannelContent) => (
									<div
										key={file.id}
										className={`border p-2 border-2 ${
											file.id === selectedFile.id ? 'border-[#0053e4]' : 'border-[#ededed]'
										} w-full`}>
										<div className="flex w-full justify-between items-center">
											<div className="flex items-center gap-2">
												<TextStyleBodyDefaultMedium>{file.custom_name ?? file.file_name}</TextStyleBodyDefaultMedium>
												<TextStyleCaption>Type: {file.extension}</TextStyleCaption>
												<TextStyleCaption>Size: {file.file_size}</TextStyleCaption>
												<TextStyleCaption>Date: {formatDate(file.created_at)}</TextStyleCaption>
											</div>
											<input type="radio" name="selectedFile" onChange={() => handleSelectedFile(file)} />
										</div>
									</div>
								))}
							</div>
						) : (
							<TextStyleCaption>
								This channel has no content to create a broadcast. Ensure content is added in the content tab before
								creating a broadcast.
							</TextStyleCaption>
						)}
					</div>
				</Modal>
			) : (
				''
			)}
			<div className="w-full flex justify-end items-end">
				<Button value="Add Transmission" icon="add" size="small" onClick={() => setShowModal(true)} />
			</div>
			{inFetch ? (
				<Loading />
			) : transmissions && Object.keys(transmissions).length ? (
				Object.entries(transmissions).map(([date, transmissionsForDate]) => (
					<div key={date} className="mb-4">
						<TextStyleH2 className="text-lg font-semibold ">{date}</TextStyleH2>
						<div className='flex flex-col gap-2'>
                        {Array.isArray(transmissionsForDate) &&
							transmissionsForDate.map((transmission: ITransmission, key: number) => (
								<div key={key} className="border p-2 border-2 border-[#ededed] rounded-[4px] w-full cursor-default">
									<div className="flex w-full justify-between items-center">
										<TextStyleBodyDefaultMedium>{transmission.transmission_name}</TextStyleBodyDefaultMedium>
										<div className="flex items-center gap-2">
											<TextStyleCaption>
												
												<b>
													{transmission.init_time} - {transmission.end_time}
												</b>
											</TextStyleCaption>
											<Button iconOnly icon="delete" type="critical" value="Delete" size="small" />
										</div>
									</div>
								</div>
							))}
                            </div>
					</div>
				))
			) : (
				<TextStyleCaption>This channel still has no Transmissions to display</TextStyleCaption>
			)}
		</div>
	)
}
