import React from 'react'
import { web3Accounts, web3Enable } from '@polkadot/extension-dapp'
import { web3FromSource } from '@polkadot/extension-dapp'
import './style.scss'
import { stringToHex } from '@polkadot/util'
import { Accounts } from './components/accounts'
import Cookies from 'js-cookie'
import Modal from 'src/components/_Dsc/Modal'
import { CardContainer, CardInner } from 'src/components/_Dsc/Card'
import KineContext from 'src/context/KineContext'
import { login } from 'src/service/profile/profileService'
import { Loading } from 'src/components/loading'
import Button from 'src/components/_Dsc/Button'

interface isLogin {
	isLogin: boolean
	setIsLogin: any
}

const Login: React.FC<isLogin> = ({ isLogin, setIsLogin }) => {
	const { setUser } = React.useContext(KineContext)
	const [accounts, setAccounts] = React.useState([] as any)
	const [provider, setProvider] = React.useState(false)
	const [isLoginFetch, setIsLoginFetch] = React.useState(false)
	const [password, setPassword] = React.useState('')
	const [errorMessage, setErrorMessage] = React.useState('')
	const [currentAccount, setCurrentAccount] = React.useState<any>([])

	const waitForInjectedAccount = async (interval = 1000): Promise<any> => {
		return new Promise((resolve, reject) => {
			const checkAccounts = async () => {
				const extensions = await web3Enable('kinera')
				if (extensions.length > 0) {
					const accounts: any[] = await web3Accounts()
					resolve(extensions)
					setAccounts(accounts)
					if (accounts.length > 0) {
						setProvider(true)
					}
				} else {
					console.log('No injected accounts found. Retrying...')
					setTimeout(checkAccounts, interval)
				}
			}
			checkAccounts()
		})
	}

	const onCancelLogin = () => {
		setIsLogin(false)
		setPassword('')
		setIsLoginFetch(false)
	}

	const onLogin = async () => {
		if (!currentAccount || !currentAccount.meta) {
			return
		}
		const injector = await web3FromSource(currentAccount.meta.source)
		const signRaw = injector?.signer?.signRaw

		if (signRaw) {
			setIsLoginFetch(true)
			const { address } = currentAccount
			const signMessage = 'Im connecting my wallet to Kinera'

			signRaw({
				address,
				data: stringToHex(signMessage),
				type: 'bytes',
			})
				.then(async signature => {
					try {
						const req = await login(currentAccount.address, password ? password : '')
						if (req && req.data && req.data.error) {
							setErrorMessage(req.data.message)
							setPassword('')
							let userData = Cookies.get('kinera-cookie')
							if (userData) {
								Cookies.remove('kinera-cookie')
							}
							setIsLoginFetch(false)
							return false
						} else if (req && req.data && !req.data.message) {
							setUser(currentAccount)
							setIsLogin(false)
						}
						setIsLoginFetch(false)
					} catch (error) {
						console.log('deu erro', error)
					}
				})
				.catch(error => {
					console.error('Failed to sign:', error)
				})
		}
	}

	React.useEffect(() => {
		setPassword('')
		const checkInjectedAccounts = async () => {
			try {
				await waitForInjectedAccount()
			} catch (error) {
				console.error('Error checking injected accounts:', error)
			}
		}

		checkInjectedAccounts()
	}, [])

	return (
		<div className="overflow-visible">
			{isLogin && (
				<Modal
					open={isLogin}
					title={'Login'}
					onClose={onCancelLogin}
					type="primary"
					size="large"
					cancelLabel="Cancel"
					confirmLabel="Login"
					onCancel={onCancelLogin}
					onConfirm={onLogin}
					confirmDisabled={isLoginFetch || !provider}
				>
					<CardInner>
						<CardContainer>
							{isLoginFetch ? (
								<Loading />
							) : (
								<>
									{provider ? (
										accounts && accounts.length ? (
											<Accounts
												errorMessage={errorMessage}
												password={password}
												setPassword={setPassword}
												extensionSetup={waitForInjectedAccount}
												accounts={accounts}
												setIsLogin={setIsLogin}
												currentAccount={currentAccount}
												setCurrentAccount={setCurrentAccount}
											/>
										) : (
											<div className="flex flex-col items-end justify-end items-center">
												<p>
													Please install a Polkadot wallet browser extension to use this dApp. If you have already
													installed it, allow this application to access it.
												</p>
												<Button value="Refresh" size="small" onClick={waitForInjectedAccount} />
											</div>
										)
									) : (
										<div className="flex flex-col items-end justify-end items-center">
											<p>
												Please install a Polkadot wallet browser extension to use this dApp. If you have already
												installed it, allow this application to access it.
											</p>
											<Button value="Refresh" size="small" onClick={waitForInjectedAccount} />
										</div>
									)}
								</>
							)}
						</CardContainer>
					</CardInner>
				</Modal>
			)}
		</div>
	)
}

export default Login
