import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Button from 'src/components/_Dsc/Button'
import Modal from 'src/components/_Dsc/Modal'
import { TextStyleBodyLarge, TextStyleH1 } from 'src/components/_Dsc/typography'

import KineContext from 'src/context/KineContext'
import { Community } from 'src/pages/communities'
import { communityService } from 'src/service/community/communityService'

interface Props {
	communityDetails: Community
}

export const Settings: React.FC<Props> = ({ communityDetails }) => {
	const { user, setOnQueue, handleGlobalMessage, apiState } = React.useContext(KineContext)
	const [showModal, setShowModal] = useState<boolean>(false)
	const navigate = useNavigate()

	const onClose = () => {
		setShowModal(false)
	}

	const onConfirm = async () => {
		setShowModal(false)
		setOnQueue(true)
		handleGlobalMessage('Your action is being processed')
		await communityService.deleteCommunity(user, communityDetails.id, apiState)
		navigate('/my-communities')
	}

	return (
		<div className="flex flex-col">
			<Modal
				open={showModal}
				title="Remove community"
				cancelLabel="Cancel"
				onClose={onClose}
				onCancel={onClose}
				onConfirm={onConfirm}
				confirmLabel="Confirm"
			>
				<TextStyleH1>Are you sure you want to delete this community?</TextStyleH1>
				<TextStyleBodyLarge>
					This action is irreversible and will result in the deletion of all existing data associated with this
					community. Please confirm if you wish to proceed.
				</TextStyleBodyLarge>
			</Modal>
			<div className="flex items-center gap-2">
				{user && user.address && user.address === communityDetails.createdBy ? (
					<Button
						value="Delete community"
						size="small"
						icon="delete"
						type="critical"
						onClick={() => {
							setShowModal(true)
						}}
					/>
				) : (
					''
				)}
				<Button value="Edit community [soon]" size="small" icon="delete" />
			</div>
		</div>
	)
}
