import React, { useState } from 'react'

import { useNavigate } from 'react-router-dom'
import KineContext from '../../../context/KineContext'

import '../createProfile/style.scss'
import { Loading } from '../../../components/loading'

import Input from '@mui/material/Input'

import { useLocation } from 'react-router-dom'
import { createUserData, updateUser } from '../../../service/profile/profileService'
import './style.scss'
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage'
import { storage } from 'src/service/storage/firebase'
import { TextStyleH1 } from 'src/components/_Dsc/typography'
import { CardContainer, CardInner } from 'src/components/_Dsc/Card'
import InputText from 'src/components/_Dsc/InputText'
import Button from 'src/components/_Dsc/Button'
import Breadcrumb from 'src/components/_Dsc/Breadcrumb'

const EditProfile: React.FC = () => {
	const path = [
		{ label: 'Social', link: '/social' },
		{ label: 'Edit profile', link: '' },
	]
	const navigate = useNavigate()
	const location = useLocation()
	const dataParam = location.state as any
	const { user, color } = React.useContext(KineContext)
	const [name, setName] = useState('')
	const [userAddress, setUser] = useState('')
	const [description, setDescription] = useState('')
	const [password, setPassword] = useState<any>(null)
	const [tag, setTag] = useState('')
	const [fetch, setFetch] = useState(false)
	const [image, setImage] = useState<any>(null)
	const [coverImage, setCoverImage] = useState<any>(null)
	const [coverImagePreview, setCoverImagePreview] = useState<any>(null)
	const [previewImage, setPreviewImage] = useState<string | null>(null)
	const [editPassword, setEditPassword] = useState(false)

	const handleSubmit = async () => {
		if (!name) {
			alert('Name is required!')
			return
		}

		setFetch(true)
		//globals.encryptUserAddress(userAddress)
		const formData = new FormData()
		formData.append('img', image ? image : null)
		formData.append('user', userAddress)
		formData.append('name', name)
		formData.append('description', description)
		formData.append('update', 'update')
		formData.append('cover', coverImage ? coverImage : null)
		formData.append('password', password)
		formData.append('tag', tag)
		try {
			let req = await updateUser(formData)

			if (req && req.data && req.name !== 'AxiosError') {
				navigate('/social/view/' + userAddress)
			}
			if (req && req.response && req.response.data && req.response.data.error) {
				setFetch(false)
				alert(req.response.data.message)
			}
		} catch (error) {
			console.error('Erro ao enviar o formulário:', error)
		}
	}

	const getImageWithNewName = (originalFile: any, newName: any) => {
		const blob = new Blob([originalFile], { type: originalFile.type })
		const newFile = new File([blob], newName, { type: originalFile.type })
		return newFile
	}

	const getImageUrl = async (event: any) => {
		if (event.target.files && event.target.files.length > 0) {
			const originalFile = event.target.files[0]

			// Renomeia o arquivo para 'profile'

			const renamedFile = getImageWithNewName(originalFile, 'profile')
			const storageRef = ref(storage, `files/${originalFile.name}`)
			const uploadTask = uploadBytesResumable(storageRef, originalFile)
			const snapshot = await uploadTask

			let urlProfile = await getDownloadURL(snapshot.ref)

			setImage(urlProfile)

			const reader = new FileReader()
			reader.onload = e => {
				if (e.target && typeof e.target.result === 'string') {
					setPreviewImage(e.target.result)
				}
			}
			reader.readAsDataURL(renamedFile)
		} else {
			setImage(null)
			setPreviewImage(null)
		}
	}

	const getImageCoverUrl = async (event: any) => {
		if (event.target.files && event.target.files.length > 0) {
			const originalFile = event.target.files[0]

			const renamedFile = getImageWithNewName(originalFile, 'cover')
			const storageRef = ref(storage, `files/${originalFile.name}`)
			const uploadTask = uploadBytesResumable(storageRef, originalFile)
			const snapshot = await uploadTask

			let urlProfile = await getDownloadURL(snapshot.ref)
			//console.log('urlProfile', urlProfile)
			setCoverImage(urlProfile)

			const reader = new FileReader()
			reader.onload = e => {
				if (e.target && typeof e.target.result === 'string') {
					setCoverImagePreview(e.target.result)
				}
			}
			reader.readAsDataURL(renamedFile)
		} else {
			setCoverImage(null)
		}
	}

	const onCancel = () => {
		navigate('/social')
	}

	const handleEnableChangePassword = () => {
		setEditPassword(true)
	}

	React.useEffect(() => {
		if (!user || !user.address || user.address !== dataParam.user) {
			navigate('/')
		} else {
			setPreviewImage(dataParam.img)
			setDescription(dataParam.description)
			setName(dataParam.name)
			setUser(dataParam.user)
			setCoverImagePreview(dataParam.cover)
			setCoverImage(dataParam.cover)
			setImage(dataParam.img)
			setTag(dataParam.tag)
		}
	}, [dataParam, user, navigate])

	return (
		<div className={`page-padding`}>
			<Breadcrumb routes={path} />
			<CardInner className="bg-white  m-auto">
				<CardContainer>
					{user && user.address ? (
						<div className="flex flex-col w-full">
							<TextStyleH1
								className={`text-3xl font-extrabold leading-9 tracking-tight  sm:text-4xl sm:leading-10 md:text-6xl md:leading-14 mb-2`}
								style={{ color: color.title }}
							>
								Edit Social profile
							</TextStyleH1>
							<>
								<div>
									{previewImage && (
										<div className="mb-4 relative flex ">
											<div className="p-4">
												<img
													src={previewImage}
													alt="preview"
													style={{
														width: '200px',
														height: '200px',
														objectFit: 'cover',
														zIndex: '10',
														overflow: 'hidden',
														borderRadius: '100%',
														position: 'relative',
													}}
												/>
											</div>
											<div
												className="w-full flex items-center cover-image-bg"
												style={{
													backgroundImage: `url(${coverImagePreview})`,
													backgroundSize: 'cover',

													backgroundPosition: 'center',
													position: 'absolute',
												}}
											></div>
										</div>
									)}
								</div>
								<div className="flex gap-10 items-center">
									{
										<div className="mb-4">
											<label htmlFor="image" className={`block font-medium mb-2 text-[${color.text}]`}>
												Profile Image
											</label>
											<Input
												type="file"
												id="image"
												inputProps={{ accept: '.jpg, .jpeg, .png' }}
												onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
													getImageUrl(event)
												}}
												style={{ display: 'none' }}
											/>
											<label htmlFor="image">
												<Button value="	Upload Image" size="small"></Button>
											</label>
										</div>
									}

									{
										<div className="mb-4">
											<label htmlFor="cover" className={`block font-medium mb-2 text-[${color.text}]`}>
												Cover Image
											</label>
											<Input
												type="file"
												id="cover"
												inputProps={{ accept: '.jpg, .jpeg, .png' }}
												onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
													getImageCoverUrl(event)
												}}
												style={{ display: 'none' }}
											/>
											<label htmlFor="cover">
												<Button value="Cover Image" size="small"></Button>
											</label>
										</div>
									}
								</div>
								<div className="flex flex-col gap-4 mb-4">
									<InputText
										label="Username"
										placeholder="Unique identifier ex: cross141..."
										value={tag}
										onChange={setTag}
									/>
									<InputText label="Name" placeholder="Social Name.." value={name} onChange={setName} />
									<InputText label="Bio" placeholder="Description.." value={description} onChange={setDescription} />

									<Button
										size="small"
										value="Change Password"
										onClick={() => {
											handleEnableChangePassword()
										}}
										disabled={!name || !name.length}
									></Button>

									{editPassword && (
										<InputText label="New Password" type="password" value={password} onChange={setPassword} />
									)}
								</div>
							</>
							{!fetch ? (
								<>
									<div className="w-full flex item-end justify-end gap-5">
										<Button type="subtle" value="Cancel" onClick={onCancel} size="small"></Button>
										<Button
											size="small"
											value="Save"
											onClick={() => {
												handleSubmit()
											}}
											disabled={!name || !name.length}
										></Button>
									</div>
								</>
							) : (
								<Loading />
							)}
						</div>
					) : (
						<p className={`text-[${color.text}]`}>Connect your account to continue</p>
					)}
				</CardContainer>
			</CardInner>
		</div>
	)
}
export default EditProfile
