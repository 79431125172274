import React, { useEffect, useState } from 'react'
import Breadcrumb from 'src/components/_Dsc/Breadcrumb'
import { CardContainer, CardInner } from 'src/components/_Dsc/Card'
import {
	TextStyleBodyDefault,
	TextStyleBodyDefaultLarge,
	TextStyleCaption,
	TextStyleH1,
} from 'src/components/_Dsc/typography'
import { Community } from '../communities'
import KineContext from 'src/context/KineContext'
import { communityService } from 'src/service/community/communityService'
import { Loading } from 'src/components/loading'

import { useNavigate, useParams } from 'react-router-dom'
import Button from 'src/components/_Dsc/Button'
import Tabs from 'src/components/_Dsc/Tabs'
import TweetBox from '../social/components/createTweet/TweetBox'
import { getCommunityPostsPublic, getCommunityPublicUpdatePosts } from 'src/service/social/socialService'
import Feed from '../social/components/feed/Feed'
import { Skeleton } from '@mui/material'

import CommunityProposal from '../communityProposal'
import UserCommunityProposal from './components/Proposal'
import PendingApprovalModal from './components/PendingApprovalModal'
import { CommunitySharedDocuments } from './components/Storage/components/CommunitySharedDocuments'

import { CommunityStorage } from './components/Storage'
import { CommunityGovernance } from './components/Governance'
import Members from './components/Governance/Members'
import { ProdutionTools } from 'src/components/ProdutionsTools'
import { decodeHex } from 'src/constants/functions'
import UserBalance from 'src/components/balances'
import { planksToTokens } from 'src/service/substrate/formated'
import Modal from 'src/components/_Dsc/Modal'
import { Bazzar } from './components/Bazzar'
import VoiceChannel, { UserVoice } from './components/VoiceChat'
import OnlineMembers from './components/OnlineMembers'
import InitialSimbol from 'src/components/_Dsc/InitialSimbol'
import Controls from './components/VoiceChat/components/PeersControl'
import { useHMSStore, selectPeers } from '@100mslive/react-sdk'
import { Channels } from './components/Channels'

type Props = {
	communityDetails: Community
}

const interactions = [1, 2, 3, 4, 5]

const path = [
	{ label: 'Home', link: '/' },
	{ label: 'Community', link: '' },
]

let categories = [
	{
		label: 'Community Timeline',
		value: 'Posts',
	},

	{
		label: 'Channel',
		value: 'channels',
	},

	{
		label: 'Resource Center',
		value: 'Storage',
	},

	{
		label: 'Collaborative Texts',
		value: 'Community Documents',
	},

	{
		label: `Tools`,
		value: 'Tools',
	},

	{
		label: 'Members',
		value: 'Members',
	},

	{
		label: 'Bazaar',
		value: 'Bazaar',
	},
]

let options = [
	{ label: 'General', value: 'posts', icon: 'groups' },
	{ label: 'Updates', value: 'updates', icon: 'update' },
	{ label: 'Voice Channel', value: 'voice', icon: 'interpreter_mode' },
]

let optionsClone = [
	{ label: 'General', value: 'posts', icon: 'groups' },
	{ label: 'Updates', value: 'updates', icon: 'update' },
	{ label: 'Voice Channel', value: 'voice', icon: 'interpreter_mode' },
]

const PublicCommunityDetail: React.FC<Props> = ({ communityDetails }) => {
	const { user, apiState, profile, onQueue, handleGlobalMessage, setOnQueue, setCurrentPermissionComunity } =
		React.useContext(KineContext)
	const peers = useHMSStore(selectPeers)
	const [isSelected, setIsSelected] = useState('Posts')
	const [isSelectedTimeline, setIsSelectedTimeline] = useState('posts')
	const [onlineMembersVoice, setOnlineMembersVoice] = useState<UserVoice[]>([])
	const [inFetch, setInFetch] = useState<boolean>(true)
	const [approvalModal, setApprovalModal] = useState(false)
	const [inFetchPosts, setInFetchPosts] = useState<boolean>(true)
	const [data, setData] = React.useState<any>([])
	const [updatePosts, setUpdatePosts] = React.useState<any>([])
	const [hasUserPermission, setHasUserPermission] = useState<boolean>(false)
	const [inFetchBecome, setInFetchBecome] = useState<boolean>(false)
	const [monthlyFee, setMonthlyFee] = useState<string | null>(null)
	const { id } = useParams()
	const navigate = useNavigate()

	const getCommunityDetails = async () => {
		setInFetch(true)
		let req = communityDetails
		console.log('req', req)
		const communityType = req?.communityType
		const fee =
			communityType?.SemiPublicOrganisation?.monthlyFee ||
			communityType?.PublicDao?.monthlyFee ||
			communityType?.Public?.monthlyFee
		setMonthlyFee(fee)
		let verifyUserCommunity = false
		setInFetchPosts(true)
		await communityPublicPosts()

		if (user && user.address) {
			let reqByUser = await communityService.getCommunitiesByUser(user.address, apiState)
			if (req && req.id && reqByUser && reqByUser.length) {
				reqByUser.forEach((val: Community) => {
					if (val.id === req.id) {
						verifyUserCommunity = true
						//communityPosts()

						if (user && req.createdBy === user.address) {
							// Verifica se já existe um objeto com o mesmo valor no array
							const alreadyExists = categories.some(category => category.value === 'Governance')
							if (!alreadyExists) {
								categories.push({
									label: 'Governance',
									value: 'Governance',
								})
							}
						}
					}
				})
			} else {
				verifyUserCommunity = false
			}
		}
		console.log('verifyUserCommunity', verifyUserCommunity)
		setHasUserPermission(verifyUserCommunity)
		setCurrentPermissionComunity(verifyUserCommunity)

		setInFetch(false)
	}

	const handleTabsChange = async (val: string) => {
		if (val === 'updates') {
			await communityUpdatePosts()
		} else {
			await communityPublicPosts()
		}
		setIsSelectedTimeline(val)
	}

	const setFilterNotifications = (val: string) => {
		if (val !== 'Posts') {
			optionsClone = options.filter((val: { label: string; value: string }) => val.value === 'voice')
		} else {
			optionsClone = options
		}
		setIsSelected(val)
	}

	const communityPublicPosts = async () => {
		setInFetchPosts(true)
		let req = await getCommunityPostsPublic(window.location.pathname.split('/')[2])
		setData(req)
		setInFetchPosts(false)
	}

	const communityUpdatePosts = async () => {
		setInFetchPosts(true)
		let req = await getCommunityPublicUpdatePosts(window.location.pathname.split('/')[2])
		setUpdatePosts(req)
		setInFetchPosts(false)
	}

	const applyUserForCommunity = async (community: Community) => {
		setInFetchBecome(true)
		await communityService.applyForCommunity(user, community, handleGlobalMessage, apiState)
		setHasUserPermission(true)
		setCurrentPermissionComunity(true)
		setInFetchBecome(false)
	}

	const getRefreshPosts = async () => {
		let req = await getCommunityPostsPublic(window.location.pathname.split('/')[2])
		setData(req)
	}

	useEffect(() => {
		//console.log('user',user)
		setIsSelected('Posts')
		if (apiState) {
			getCommunityDetails()
			if (isSelectedTimeline === 'posts') {
				const interval = setInterval(() => {
					getRefreshPosts()
				}, 10000) // 5000 ms = 5 segundos

				return () => clearInterval(interval)
			}
		}
	}, [user, apiState])

	return (
		<div className="page-padding w-full h-full">
			<Breadcrumb routes={path} />
			<CardInner className="w-full h-full">
				<CardContainer className="gap-2 flex flex-col w-full h-full justify-start">
					<div className="flex items-start justify-between">
						<div className="w-full flex flex-col">
							<div className="flex items-center justify-between">
								<TextStyleH1>
									{communityDetails && communityDetails.name ? decodeHex(communityDetails.name) : 'Community'}{' '}
								</TextStyleH1>

								{!inFetch &&
								hasUserPermission &&
								user &&
								communityDetails &&
								communityDetails.createdBy === user.address &&
								Object.keys(communityDetails.communityType)[0] === 'SemiPublicOrganisation' &&
								monthlyFee &&
								parseInt(monthlyFee) == 0 ? (
									<Button
										value="Pending approval"
										size="small"
										onClick={() => {
											setApprovalModal(true)
										}}
									/>
								) : (
									''
								)}
							</div>
							<TextStyleCaption>{decodeHex(communityDetails.description)}</TextStyleCaption>
							<Tabs onChange={setFilterNotifications} options={categories} value={isSelected}></Tabs>
						</div>
						<div>
							{!hasUserPermission && !inFetch ? (
								<div>
									<Button
										disabled={inFetchBecome}
										value="Become a member"
										size="small"
										onClick={() => {
											applyUserForCommunity(communityDetails)
										}}
									/>
								</div>
							) : (
								''
							)}
						</div>
					</div>

					<div className="flex items-start gap-4 h-full w-full">
						<div className="options whitespace-nowrap flex flex-col gap-2 h-full">
							{optionsClone.map((val: { label: string; value: string; icon: string }, key: number) => (
								<div
									key={key}
									onClick={() => {
										handleTabsChange(val.value)
									}}
									className={`${
										val.value === isSelectedTimeline
											? 'text-blue-600 bg-gray-200 rounded-[4px] flex items-center gap-1'
											: ''
									} p-1 cursor-pointer hover:bg-gray-200 flex items-center gap-1`}
								>
									<TextStyleBodyDefaultLarge>{val.label}</TextStyleBodyDefaultLarge>
									<Button value={val.icon} iconOnly icon={val.icon} size="small" type="subtle" />
								</div>
							))}
							<div className="flex flex-col gap-2">
								{isSelected !== 'Posts' && isSelectedTimeline === 'voice' ? (
									<div className="flex flex-col gap-2">
										<TextStyleCaption>Connected Voice:</TextStyleCaption>
										{peers && peers.length > 0
											? peers.map((member: any, key: number) => (
													<div key={key} className="flex items-center gap-2">
														<TextStyleCaption>{member.name}</TextStyleCaption>
													</div>
											  ))
											: ''}
									</div>
								) : (
									''
								)}
							</div>
							<div className="mt-10 flex flex-col justify-between w-full h-full">
								<OnlineMembers user={user} />
								{user && user.address && isSelectedTimeline === 'voice' ? (
									<div className="flex flex-col gap-2 bg-white p-2 rounded-[4px]">
										<div className="flex items-center gap-2">
											<InitialSimbol profile={{ img: user.img, name: user.name }} />
											<TextStyleCaption>{user.name}</TextStyleCaption>
											<div className="min-w-[10px] min-h-[10px] bg-green-500 rounded-full" />
										</div>
										<Controls setIsSelectedTimeline={setIsSelectedTimeline} />
									</div>
								) : (
									''
								)}
							</div>
						</div>
						<div className="flex flex-col gap-2 w-full rounded-[4px] ">
							{inFetch ? (
								<div className="p-4">
									<Loading />
								</div>
							) : (
								<div className="flex flex-col gap-2">
									{isSelectedTimeline === 'voice'
										? user &&
										  user.address && (
												<VoiceChannel
													setIsSelectedTimeline={setIsSelectedTimeline}
													user={user}
													tab={isSelected}
													setOnlineMembersVoice={setOnlineMembersVoice}
												/>
										  )
										: ''}
									{isSelected === `Posts` ? (
										<div className="flex flex-col gap-2 ">
											{isSelectedTimeline === 'posts' ? (
												<div>
													{communityDetails && communityDetails.name ? (
														<div className="flex flex-col gap-2">
															{hasUserPermission ? (
																<TweetBox
																	getData={communityPublicPosts}
																	setData={setData}
																	community={communityDetails}
																></TweetBox>
															) : (
																<div className="w-full flex items-center justify-center">
																	<TextStyleCaption>
																		To post to public community timelines, you must join the member list
																	</TextStyleCaption>
																</div>
															)}
															<div className="">
																{!inFetchPosts ? (
																	<Feed posts={data} profile={profile} setData={setData} />
																) : (
																	interactions.map((value: any, index: number) => (
																		<div className="flex flex-col items-center p-4 border-y border-y-2" key={index}>
																			<div className="flex items-center justify-between mb-2 w-full">
																				<Skeleton variant="circular" width={60} height={60} />
																				<Skeleton variant="text" sx={{ fontSize: '1rem' }} width={'80%'} />
																			</div>
																			<Skeleton variant="rectangular" width={'100%'} height={100} />
																			<div className="mt-2 mb-2 w-full">
																				<Skeleton variant="text" sx={{ fontSize: '1rem' }} width={'30%'} />
																			</div>
																		</div>
																	))
																)}
															</div>
														</div>
													) : (
														<p>No data to display</p>
													)}
												</div>
											) : isSelectedTimeline === 'updates' ? (
												<div>
													{communityDetails && communityDetails.name ? (
														<div className="flex flex-col gap-2">
															{hasUserPermission ? (
																<TweetBox
																	getData={communityPublicPosts}
																	setData={setData}
																	community={communityDetails}
																></TweetBox>
															) : (
																<div className="w-full flex items-center justify-center">
																	<TextStyleCaption>
																		To post to public community timelines, you must join the member list
																	</TextStyleCaption>
																</div>
															)}
															<div className="">
																{!inFetchPosts ? (
																	<Feed posts={updatePosts} profile={profile} setData={setData} />
																) : (
																	interactions.map((value: any, index: number) => (
																		<div className="flex flex-col items-center p-4 border-y border-y-2" key={index}>
																			<div className="flex items-center justify-between mb-2 w-full">
																				<Skeleton variant="circular" width={60} height={60} />
																				<Skeleton variant="text" sx={{ fontSize: '1rem' }} width={'80%'} />
																			</div>
																			<Skeleton variant="rectangular" width={'100%'} height={100} />
																			<div className="mt-2 mb-2 w-full">
																				<Skeleton variant="text" sx={{ fontSize: '1rem' }} width={'30%'} />
																			</div>
																		</div>
																	))
																)}
															</div>
														</div>
													) : (
														<p>No data to display</p>
													)}
												</div>
											) : (
												''
											)}
										</div>
									) : isSelected === 'Governance' ? (
										<CommunityGovernance
											communityDetails={communityDetails}
											open={approvalModal}
											setOpen={setApprovalModal}
										/>
									) : isSelected === 'Community Documents' ? (
										id ? (
											<CommunitySharedDocuments community_id={id} />
										) : (
											''
										)
									) : isSelected === 'channels' ? (
										id ? (
											<Channels community_id={id} />
										) : (
											''
										)
									) : isSelected === 'Proposals' ? (
										id ? (
											<UserCommunityProposal community_id={id} />
										) : (
											''
										)
									) : isSelected === 'Storage' ? (
										id ? (
											<CommunityStorage community_id={id} />
										) : (
											''
										)
									) : isSelected === 'Members' ? (
										<Members isGovernance={false} communityDetails={communityDetails}/>
									) : isSelected === 'Tools' ? (
										<ProdutionTools isCommunity={true} community_id={id} hasUserPermission={hasUserPermission} />
									) : isSelected === 'Bazaar' ? (
										id ? (
											<Bazzar />
										) : (
											''
										)
									) : (
										''
									)}
								</div>
							)}
						</div>
					</div>
				</CardContainer>
			</CardInner>
		</div>
	)
}

export default PublicCommunityDetail
