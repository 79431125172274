import CryptoJS from 'crypto-js'

const getVideoSrc = async (link: string, set?: any, type: string = '') => {
	try {
		if (!type || type === 'youtube') {
			const response = await fetch(`https://www.youtube.com/oembed?url=${link}&format=json`)

			if (response.ok) {
				const videoData = await response.json()

				set({
					html: videoData.html,
					title: videoData.title,
					author: videoData.author_name,
					thumbnail_url: videoData.thumbnail_url,
					link: link,
				})

				return videoData
			} else {
				throw new Error('Não foi possível obter a URL do vídeo.')
			}
		} else if (type === 'tiktok') {
			const response = await fetch(`https://www.tiktok.com/oembed?url=${link}`)
			if (response.ok) {
				const videoData = await response.json()

				set({
					html: `https://www.tiktok.com/embed/v2/${videoData.embed_product_id}`,
					title: videoData.title,
					author: videoData.author_name,
					thumbnail_url: videoData.thumbnail_url,
					link: link,
					id: videoData.embed_product_id,
					url: link,
				})
				return videoData.thumbnail_url
			}
		}
	} catch (error) {
		console.error(error)
	}
}

const encryptUserAddress = (address: string) => {
	const processedKey = CryptoJS.SHA256(CryptoJS.enc.Utf8.parse('@@kinera@@..'))
	const processedIV = CryptoJS.lib.WordArray.create(
		CryptoJS.SHA256(CryptoJS.enc.Utf8.parse('arieiroçç')).words.slice(0, 4),
	)

	const encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(address), processedKey, {
		iv: processedIV,
		mode: CryptoJS.mode.CBC,
		padding: CryptoJS.pad.Pkcs7,
	})

	return encrypted.toString()
}

export const formatDate = (dateString: string) => {
	const date = new Date(dateString)
	const day = String(date.getDate()).padStart(2, '0')
	const month = String(date.getMonth() + 1).padStart(2, '0') // Months are 0-indexed
	const year = date.getFullYear()
	const time = date.toTimeString().split(' ')[0] // Get the time portion only (HH:MM:SS)

	return `${day}/${month}/${year} - ${time}`
}

export const globals = {
	getVideoSrc,
	encryptUserAddress,
}
