import { ApiPromise, WsProvider } from '@polkadot/api'
import jsonrpc from '@polkadot/types/interfaces/jsonrpc'
import { metadata } from './metadata'

let apiInstance: ApiPromise | null = null // Armazena a instância da API
let isConnecting = false // Para evitar múltiplas tentativas simultâneas

export async function api() {
	if (apiInstance) {
		// Retorna a instância existente se já estiver conectada
		return apiInstance
	}

	if (isConnecting) {
		// Espera até que a conexão em andamento seja concluída
		return new Promise((resolve, reject) => {
			const checkConnection = () => {
				if (apiInstance) {
					resolve(apiInstance)
				} else {
					setTimeout(checkConnection, 500)
				}
			}
			checkConnection()
		})
	}

	isConnecting = true

	try {
		const isLocal = window.location.host.includes('localhost')
		const url = isLocal ? 'wss://dev.kinera.network' : 'wss://node.kinera.network'

		const wsProvider = new WsProvider(url)
		const types = metadata

		apiInstance = await ApiPromise.create({
			provider: wsProvider,
			types,
			rpc: jsonrpc,
		})

		console.log('Conexão com a API estabelecida')
		return apiInstance
	} catch (error) {
		console.error('Erro ao conectar à API:', error)
		apiInstance = null
		throw error
	} finally {
		isConnecting = false
	}
}
