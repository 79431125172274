import React, { useEffect, useState } from 'react'
import Breadcrumb from 'src/components/_Dsc/Breadcrumb'
import { CardContainer, CardInner } from 'src/components/_Dsc/Card'
import { TextStyleBodyDefault, TextStyleCaption, TextStyleH1, TextStyleH3 } from 'src/components/_Dsc/typography'
import { Community } from '../communities'
import KineContext from 'src/context/KineContext'
import { communityService } from 'src/service/community/communityService'
import { Loading } from 'src/components/loading'
import DscIcons from 'src/components/_Dsc/icons'

import { useNavigate } from 'react-router-dom'
import Button from 'src/components/_Dsc/Button'
import { decodeHex } from 'src/constants/functions'

const path = [
	{ label: 'Home', link: '/' },
	{ label: 'Communities', link: '/communities' },
	{ label: 'My Communities', link: '' },
]

const UserCommunities: React.FC = () => {
	const { user, apiState } = React.useContext(KineContext)
	const navigate = useNavigate()
	const [inFetch, setInFetch] = useState<boolean>(true)
	const [communitites, setCommunities] = useState<Community[]>([])

	const getUserCommunities = async () => {
		setInFetch(true)
		let req = await communityService.getCommunitiesByUser(user.address, apiState)
		const sortedCommunities = req.sort((a: Community, b: Community) => Number(b.id) - Number(a.id))

		setCommunities(sortedCommunities)
		setInFetch(false)
	}

	const gotToCommunity = (community: Community) => {
		if (community) {
			navigate(`/community/${community.id}`)
		}
	}

	const getUserCommunitiesRefresh = async () => {
		let req = await communityService.getCommunitiesByUser(user.address, apiState)
		const sortedCommunities = req.sort((a: Community, b: Community) => Number(b.id) - Number(a.id))

		setCommunities(sortedCommunities)
	}

	useEffect(() => {
		if (user && user.address) {
			getUserCommunities()
			const interval = setInterval(() => {
				getUserCommunitiesRefresh()
			}, 2500) // 5000 ms = 5 segundos

			return () => clearInterval(interval)
		}
	}, [user])

	return (
		<div className="page-padding">
			<Breadcrumb routes={path} />
			<CardInner>
				<CardContainer className="gap-2 flex flex-col">
					<div className="w-full flex flex-col">
						<div className="flex items-center justify-between">
							<TextStyleH1>My Communities</TextStyleH1>
						</div>
					</div>
					<div className="flex flex-col gap-2 w-full rounded-[4px] mt-4">
						{inFetch ? (
							<div className="p-4">
								<Loading />
							</div>
						) : (
							<div className="flex flex-col gap-2">
								{communitites && communitites.length ? (
									communitites.map((val: Community, index: number) => (
										<div
											onClick={() => {
												gotToCommunity(val)
											}}
											key={index}
											className="bg-white p-4 flex flex-col w-full items-end justify-end gap-2 rounded-[4px] hover:opacity-70 cursor-pointer"
										>
											<div className=" flex  w-full justify-between">
												<div className="flex items-center gap-2">
													<img
														src={val.icon}
														width="60px"
														height="60px"
														className="rounded-full w-[60px] h-[60px] rounded-[100%]"
														alt="logo community"
													/>
													<div className="flex flex-col gap-1">
														<TextStyleH3> {val && val.name ? decodeHex(val.name) : ''}</TextStyleH3>
														<TextStyleBodyDefault>
															{' '}
															{val && val.description ? decodeHex(val.description) : ''}
														</TextStyleBodyDefault>
													</div>
												</div>
												<div className="flex flex-col items-end">
													<TextStyleCaption key={index}>
														{Object.keys(val.communityType)[0] === 'SemiPublicOrganisation'
															? 'Semi-Public Organisation'
															: Object.keys(val.communityType)[0] === 'PublicDao'
															? 'Public DAO'
															: Object.keys(val.communityType)[0] === 'Public'
															? 'Public'
															: `${Object.keys(val.communityType)[0]} DAO`}
													</TextStyleCaption>
													<div className="flex items-center gap-1">
														<TextStyleBodyDefault>{val.members}</TextStyleBodyDefault>
														<DscIcons icon="group" />
													</div>
												</div>
											</div>
										</div>
									))
								) : (
									<p>No data to display</p>
								)}
							</div>
						)}
					</div>
				</CardContainer>
			</CardInner>
		</div>
	)
}

export default UserCommunities
