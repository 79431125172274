import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Breadcrumb from 'src/components/_Dsc/Breadcrumb'
import Button from 'src/components/_Dsc/Button'
import { CardContainer, CardInner } from 'src/components/_Dsc/Card'
import { TextStyleBodyDefaultMedium, TextStyleH2 } from 'src/components/_Dsc/typography'
import { Loading } from 'src/components/loading'
import API from 'src/service/api'

const path = [
	{ label: 'Home', link: '/' },
	{ label: 'Transmission', link: '' },
]

export const ChannelTranmission: React.FC = () => {
	const { id } = useParams()

	const [currentTransmissions, setCurrentTransmissions] = useState<any[]>([])
	const [message, setMessage] = useState<string>('')
	const [inFetch, setInFetch] = useState<boolean>(false)
	const [timeElapsed, setTimeElapsed] = useState<number>(0)

	const getCurrentTransmission = async () => {
		setInFetch(true)
		let req = await API.get(`/api/channel/community/transmission/current/${id}`)
		setInFetch(false)

		if (req && req.data) {
			console.log('req', req.data)
			setCurrentTransmissions(req.data.transmissions || [])
			setMessage(req.data.message)
		}
	}

	useEffect(() => {
		getCurrentTransmission()
		const interval = setInterval(getCurrentTransmission, 30000) // Atualiza a cada 30 segundos
		return () => clearInterval(interval)
	}, [])

	return (
		<div>
			{inFetch ? (
				<Loading />
			) : currentTransmissions.length > 0 ? (
				currentTransmissions.map((transmission, index) => (
					<div className="page-padding w-full h-full">
						<Breadcrumb routes={path} />
						<CardInner className="w-full h-full">
							<CardContainer className="gap-2 flex flex-col w-full h-full">
								<div key={index} className="flex items-center justify-center flex-col">
									<TextStyleH2 className="text-lg font-semibold">{transmission.transmission_name}</TextStyleH2>
									<TextStyleBodyDefaultMedium className="flex items-center gap-2">
										<Button size='small' iconOnly value='timer' icon='schedule' type='subtle'/>  {transmission.init_time} - {transmission.end_time}
									</TextStyleBodyDefaultMedium>

									{transmission?.content?.extension === 'img' ? (
										<img
											src={transmission.content.url}
											alt={transmission.content.custom_name}
											className="mt-2 w-full h-auto"
										/>
									) : transmission?.content?.extension === 'video' ? (
										<video src={transmission.content.url} controls autoPlay className="mt-2 w-[700px] h-[500px]">
											Seu navegador não suporta vídeos.
										</video>
									) : (
										transmission?.content?.extension && (
											<a
												href={transmission.content.url}
												target="_blank"
												rel="noopener noreferrer"
												className="mt-2 text-blue-500 underline">
												Abrir arquivo {transmission.content.extension}
											</a>
										)
									)}
								</div>
							</CardContainer>
						</CardInner>
					</div>
				))
			) : (
				<p className="text-sm text-gray-600">{message}</p>
			)}
		</div>
	)
}
