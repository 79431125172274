import React from 'react'
import { TextStyleBodyDefaultMedium, TextStyleCaption } from 'src/components/_Dsc/typography'

export const models = [
	{
		label: 'Arena model',
		value: 'arena-model',
		description: 'Ideal for fast, responsive real-time interactions and dynamic content.',
	},
	{
		label: 'tinyllama',
		description: 'TinyLlama is a compact model with only 1.1B parameters. This compactness allows it to cater to a multitude of applications demanding a restricted computation and memory footprint.',
		value: 'tinyllama:latest',
	},
	/*
	{ label: 'gemma2:9b', value: 'gemma2:9b', description: 'Google’s Gemma 2 model is available in three sizes, 2B, 9B and 27B, featuring a brand new architecture designed for class leading performance and efficiency.' },
	*/
	{ label: 'qwen2.5:latest', description: 'Qwen2.5 is the latest series of Qwen large language models. For Qwen2.5, a range of base language models and instruction-tuned models are released, with sizes ranging from 0.5 to 72 billion parameters.', value: 'qwen2.5:latest' },
	{
		label: 'deepseek-r1',
		description: 'DeepSeek’s first-generation reasoning models, achieving performance comparable to OpenAI-o1 across math, code, and reasoning tasks.',
		value: 'deepseek-r1:latest',
	},
]

export const Models: React.FC = () => {
	return (
		<div className="flex flex-col gap-2 py-4">
			{models.map((val: { label: string; value: string; description?: string }, key: number) => (
				<div
					className="flex border border-1 border-[#ededed] p-2 rounded-[4px] cursor-pointer hover:bg-gray-100 flex-col"
					key={key}
				>
					<TextStyleBodyDefaultMedium>{val.label}</TextStyleBodyDefaultMedium>
					<TextStyleCaption>{val.description || ''}</TextStyleCaption>
				</div>
			))}
		</div>
	)
}
