import { Divider } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import Button from 'src/components/_Dsc/Button'
import { DsyLabel } from 'src/components/_Dsc/Button/styles'
import IconButton from 'src/components/_Dsc/IconButton'
import InputText from 'src/components/_Dsc/InputText'
import Modal from 'src/components/_Dsc/Modal'
import { TextStyleCaption } from 'src/components/_Dsc/typography'
import { Loading } from 'src/components/loading'
import KineContext from 'src/context/KineContext'
import { models } from 'src/pages/community/components/Actions/components/GPT/components/Models'
import { getPublicPopularCharacters } from 'src/service/community/communityService'

interface Props {
	setIsSelected: (selected: string) => void
	agents: { question: string; answer: string }
	setAgents: (agents: { question: string; answer: string }) => void
	selectedResponder: string
	setSelectedResponder: (selected: string) => void
	setSelectedAsker: (selected: string) => void
	selectedAsker: string
	confirmCreateForm: () => void
	disabled: boolean
}

export const RegisterAgents: React.FC<Props> = ({
	setIsSelected,
	agents,
	setAgents,
	selectedResponder,
	setSelectedResponder,
	setSelectedAsker,
	selectedAsker,
	confirmCreateForm,
	disabled,
}) => {
	const { user } = useContext(KineContext)
	const [popularCharacter, setPopularCharacter] = useState([])
	const [type, setType] = useState('')
	const [infetchCharacter, setInFetchCharacter] = useState<boolean>(false)
	const [showModal, setShowModal] = useState<boolean>(false)
	const handleResponderSelection = (value: string) => {
		setSelectedResponder(value)
		// Atualiza diretamente o estado de `agents` com o valor selecionado
		setAgents({
			...agents,
			answer: value,
		})
	}

	const handleAskerSelection = (value: string) => {
		setSelectedAsker(value)
		// Atualiza diretamente o estado de `agents` com o valor selecionado
		setAgents({
			...agents,
			question: value,
		})
	}

	const handleSearchByName = async (name: string) => {
		console.log(name)
	}

	const getPopularCharacters = async () => {
		setInFetchCharacter(true)
		try {
			// Example: Fetch from an API (replace with actual API)
			const req = await getPublicPopularCharacters()
			if (req && req.data) {
				setPopularCharacter(req.data)
			}

			console.log('req', req)
		} catch (error) {
			console.error('Error fetching characters:', error)
		}
		setInFetchCharacter(false)
	}

	const onShowModalType = (type: string) => {
		setShowModal(true)
		setType(type)
	}

	useEffect(() => {
		getPopularCharacters()
	}, [])

	return (
		<div className="w-full h-full flex flex-col gap-4">
			{/*
				<Modal title='Search character' open={showModal} cancelLabel='Close'  onClose={() => {setShowModal(false)}}  onCancel={() => {setShowModal(false)}}>
				<div>
					Ola
				</div>
			</Modal>
				*/}
			<TextStyleCaption>Select 2 agents to start a conversation, you can select from the options: </TextStyleCaption>

			{/* Section: Who will ask */}
			<div className="flex flex-col gap-1">
				<TextStyleCaption className='underline'>Who will ask:</TextStyleCaption>

				<div className="flex flex-col ">

					<div className="flex items-center gap-2 ">
						{infetchCharacter ? (
							<Loading />
						) : popularCharacter && popularCharacter.length ? (
							<>
								<div className="flex items-center gap-1">
									<DsyLabel className="font-[14px] text-[14px]">Character's</DsyLabel>
								</div>
								<div className="w-fit flex items-center">
									{popularCharacter.map((val: { img_url: string; name: string; id: string }, key: number) => (
										<div className="flex items-center" key={key}>
											<input
												type="radio"
												value={val.id}
												name="asker-selection"
												className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500"
												onChange={() => handleAskerSelection(val.id)}
												checked={selectedAsker === val.id}
											/>
											<label className="ms-2 text-sm font-medium text-gray-900 truncate">{val.name}</label>
											<IconButton color="text-red-600" icon="local_fire_department" size="small" />
										</div>
									))}
								</div>
								<TextStyleCaption>OR</TextStyleCaption>
						<div className="flex items-center gap-2 w-full relative ">
							{showModal && type === 'ask' ? (
								<div className="flex flex-col relative w-full ">
									<InputText placeholder="Write Character name" onChange={handleSearchByName} value="" />
									{
										<div className="absolute top-[40px] h-[120px] gap-2 rounded-[4px] p-2  bg-[#ededed] flex flex-col w-full">
											<p>asaasaasaasaasaasaasaasaasaasa</p>
										</div>
									}
								</div>
							) : (
								<Button
									value="Search"
									size="small"
									icon="search"
									onClick={() => {
										onShowModalType('ask')
									}}
								/>
							)}
						</div>
							</>
						) : (
							``
						)}

						
					</div>
				</div>

				<div className="flex flex-col gap-1">
					<div className="flex items-center gap-1">
						<DsyLabel className="font-[14px] text-[14px]">Models</DsyLabel>
					</div>
					<div className="flex items-center gap-2">
						{models.map((val: { label: string; value: string }, key: number) => (
							<div className="flex items-center" key={key}>
								<input
									type="radio"
									value={val.value}
									name="asker-selection"
									className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500"
									onChange={() => handleAskerSelection(val.value)}
									checked={selectedAsker === val.value}
								/>
								<label className="ms-2 text-sm font-medium text-gray-900 ">{val.label}</label>
							</div>
						))}
					</div>
				</div>
				<div className="flex flex-col gap-1">
					<div className="flex items-center gap-1">
						<DsyLabel className="font-[14px] text-[14px]">GPT's</DsyLabel>
					</div>
					<div className="flex items-center gap-2">
						{['chat-gpt', 'claude'].map(value => (
							<div className="flex items-center" key={value}>
								<input
									disabled={!user || !user.gpt_api_key || value === 'claude'}
									type="radio"
									value={value}
									name="asker-selection"
									className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500"
									onChange={() => handleAskerSelection(value)}
									checked={selectedAsker === value}
								/>
								<label className="ms-2 text-sm font-medium text-gray-900 ">
									{value === 'chat-gpt' ? 'Chat GPT' : 'Claude AI'}
								</label>
							</div>
						))}
					</div>
				</div>
			</div>

			{/* Section: Who will respond */}
			<div className="flex flex-col gap-1">
				<TextStyleCaption className='underline'>Who will respond:</TextStyleCaption>

				<div className="flex items-center gap-2 truncate">
					{infetchCharacter ? (
						<Loading />
					) : popularCharacter && popularCharacter.length ? (
						<>
						<div className="max-w-[90%] flex items-center truncate">
							{popularCharacter.map((val: { img_url: string; name: string; id: string }, key: number) => (
								<div className="flex items-center" key={key}>
									<input
										type="radio"
										value={val.name}
										name="asker-selection"
										className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500"
										onChange={() => handleAskerSelection(val.name)}
										checked={selectedAsker === val.name}
									/>
									<label className="ms-2 text-sm font-medium text-gray-900 truncate">{val.name}</label>
									<IconButton color="text-red-600" icon="local_fire_department" size="small" />
								</div>
							))}
						</div>
						<TextStyleCaption>OR</TextStyleCaption>
						<div className="flex items-center gap-2 w-fit">
							{/**
							 * 	<InputText placeholder='Search Character name' onChange={handleSearchByName} value=''/>
							 */}
							<Button
								value="Search"
								size="small"
								icon="search"
								onClick={() => {
									onShowModalType('respond')
								}}
							/>
						</div>
						</>
					) : (
						``
					)}

					
				</div>

				<div className="flex flex-col gap-1">
					<div className="flex items-center gap-1">
						<DsyLabel className="font-[14px] text-[14px]">Models</DsyLabel>
					</div>
					<div className="flex items-center gap-2">
						{models.map((val: { label: string; value: string }, key: number) => (
							<div className="flex items-center" key={key}>
								<input
									type="radio"
									value={val.value}
									name="responder-selection"
									className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500"
									onChange={() => handleResponderSelection(val.value)}
									checked={selectedResponder === val.value}
								/>
								<label className="ms-2 text-sm font-medium text-black ">{val.label}</label>
							</div>
						))}
					</div>
				</div>
				<div className="flex flex-col gap-1">
					<div className="flex items-center gap-1">
						<DsyLabel className="font-[14px] text-[14px]">GPT's</DsyLabel>
					</div>
					<div className="flex items-center gap-2">
						{['chat-gpt', 'claude'].map(value => (
							<div className="flex items-center" key={value}>
								<input
									disabled={!user || !user.gpt_api_key || value === 'claude'}
									type="radio"
									value={value}
									name="responder-selection"
									className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500"
									onChange={() => handleResponderSelection(value)}
									checked={selectedResponder === value}
								/>
								<label className="ms-2 text-sm font-medium text-gray-900 ">
									{value === 'chat-gpt' ? 'Chat GPT' : 'Claude AI'}
								</label>
							</div>
						))}
					</div>
				</div>
			</div>

			<Divider />

			<div className="w-full h-full flex justify-between items-center">
				<Button
					value="Return Prompts"
					type="subtle"
					icon="arrow_back"
					size="small"
					onClick={() => {
						setIsSelected('prompts')
					}}
				/>
				<Button
					disabled={disabled}
					value="Create dialog"
					icon="save_as"
					size="small"
					onClick={() => {
						confirmCreateForm()
					}}
				/>
			</div>
		</div>
	)
}
