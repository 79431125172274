import React from 'react'
import styled from 'styled-components'

interface IDsyIconButton {
	onClick?: (e: React.MouseEvent) => void
}

interface IDsyIconButtonContent {
	size?: string
	selected?: boolean
	disabled?: boolean
}

export const DsyIconButton = styled.div<IDsyIconButton>`
	display: inline-block;
	width: fit-content;
`
export const DsyIconButtonContent = styled.div<IDsyIconButtonContent>`
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	border: none;
	background-color: transparent;
	cursor: pointer;
	user-select: none;

	&:hover {
		background-color: var(--background-default);
	}

	&:active {
		background-color: var(--icon-disable);
	}

	${({ selected }) =>
		selected &&
		`
		background-color: var(--surface-neutral);

		i {
			color: var(--icon-primary);
		}

		&:hover {
			background-color: var(--surface-primary);
		}

		&:active {
			background-color: var(--icon-primary-light);
		}
	`}

	${({ disabled }) =>
		disabled &&
		`
		pointer-events: none;
		cursor: not-allowed;
		i {
			color: var(--icon-disable) !important;
		}
	`}

	${({ size }) => {
		switch (size) {
			case 'default':
				return `
					padding: 8px;
					width: 32px;
					height: 32px;

					i {
						font-size: 24px;
					}
				`
			case 'xsmall':
				return `
					padding: 5.33px;
					width: 20px;
					height: 20px;

					i {
						font-size: 14px;
					}
				`
			case 'small':
				return `
					padding: 6.67px;
					width: 24px;
					height: 24px;
					font-size: 11px;

					i {
						font-size: 16px;
					}
				`
			case 'large':
				return `
					padding: 8px;
					width: 40px;
					height: 40px;

					i {
						font-size: 24px;
					}
				`
		}
	}}
`
