import React, { useEffect, useState } from "react";
import { Character } from "../../Bazzar";
import { communityService } from "src/service/community/communityService";
import { TextStyleBodyDefaultMedium, TextStyleCaption } from "src/components/_Dsc/typography";
import { Loading } from "src/components/loading";
import { Tooltip } from "@mui/material";
import Button from "src/components/_Dsc/Button";
import Modal from "src/components/_Dsc/Modal";


interface Props {
    community_id: string
}

export const Characters:React.FC<Props> = ({community_id}) => {
    const [inFetch, setInFetch] = useState(false)
    const [characters, setCharacters] = useState<Character[]>([])
    const [showSearchCharacterModal, setShowSearchCharacterModal] = useState(false)

    const getAllCharactersCommunity = async () => {
		setInFetch(true)
        setShowSearchCharacterModal(true)
		if (community_id) {
			let req = await communityService.getAllCommunitiesCharacters(community_id)
			if (req && req.data) {
				setCharacters(req.data)
			}
		}
		setInFetch(false)
	}

    const addCharacter = async () => {
        console.log('adding character')
    }



    return (
        <div className="bg-white p-4 flex flex-col gap-2">
            {showSearchCharacterModal ? (
                            <Modal
                                open={showSearchCharacterModal}
                                title={'Remove Content'}
                                onConfirm={() => {
                                    addCharacter()
                                }}
                                confirmDisabled={inFetch}
                                type="critical"
                                confirmLabel="Confirm"
                                cancelLabel="Cancel"
                                onCancel={() => {
                                    setShowSearchCharacterModal(false)
                                }}
                                onClose={() => {
                                    setShowSearchCharacterModal(false)
                                }}
                                size="xl">
                                <div className="flex flex-col w-full gap-2">
                                    <div className="flex flex-col gap-2">
                                       <div className="bg-yellow-500 p-2 rounded-[4px]">
                                       <TextStyleCaption>
                                        For a character to be used in a broadcast, it must be listed as public in the bazaar tab.
                                        </TextStyleCaption>
                                       </div>
                                        {inFetch ? (
				<Loading />
			) : characters && characters.length ? (
				<div className="flex gap-2 flex-wrap">
					{characters.map((character: Character, key: number) => (
						<div className="text-left bg-gray-50 dark:bg-gray-850 group-hover:dark:bg-gray-850/80 min-w-64 max-w-72 border border-gray-100 dark:border-none rounded-lg flex flex-col justify-between">
							<div className="w-full relative">
								<div className="absolute right-1 top-1">
									<div className="flex gap-1 items-center">
										

										<input type="checkbox" className="w-[20px] h-[20px] z-[99999999]"/>
									</div>
								</div>
								<img src={character.img_url} alt="model" loading="lazy" />
								<div className="absolute top-0 left-0 w-full h-full bg-gradient-to-t from-gray-850 to-transparent text-white text-xs font-medium bottom-0 p-1"></div>
							</div>
							<div className="flex flex-1 min-w-0 px-2.5 py-6  z-20">
								<div className="flex flex-col justify-start h-full flex-1 self-center truncate">
									<div className="flex">
										<div className="capitalize font-medium    mb-0.5">{character.name}</div>
									</div>
									<Tooltip title={character.description}>
										<div className="text-xs truncate    truncate-text">{character.description}</div>
									</Tooltip>
								</div>
								
							</div>
						</div>
					))}
				</div>
			) : (
				<TextStyleBodyDefaultMedium>This community is still without characters</TextStyleBodyDefaultMedium>
			)}
                                    </div>
                                </div>
                            </Modal>
                        ) : (
                            ''
                        )}
            <div className="w-full flex justify-end items-end">
                            <Button
                                value="Find Characters"
                                icon="add"
                                size="small"
                               onClick={() => {getAllCharactersCommunity()}}
                            />
                        </div>
           {
            /**
             *  {inFetch ? (
				<Loading />
			) : characters && characters.length ? (
				<div className="flex gap-2 flex-wrap">
					{characters.map((character: Character, key: number) => (
						<div className="text-left bg-gray-50 dark:bg-gray-850 group-hover:dark:bg-gray-850/80 min-w-64 max-w-72 border border-gray-100 dark:border-none rounded-lg flex flex-col justify-between">
							<div className="w-full relative">
								<div className="absolute right-1 top-1">
									<div className="flex gap-1 items-center">
										Botoes
									</div>
								</div>
								<img src={character.img_url} alt="model" loading="lazy" />
								<div className="absolute top-0 left-0 w-full h-full bg-gradient-to-t from-gray-850 to-transparent text-white text-xs font-medium bottom-0 p-1"></div>
							</div>
							<div className="flex flex-1 min-w-0 px-2.5 py-6  z-20">
								<div className="flex flex-col justify-start h-full flex-1 self-center truncate">
									<div className="flex">
										<div className="capitalize font-medium    mb-0.5">{character.name}</div>
									</div>
									<Tooltip title={character.description}>
										<div className="text-xs truncate    truncate-text">{character.description}</div>
									</Tooltip>
								</div>
								<div className="flex flex-col gap-1">
									<div className="mt-1 truncate-text text-[0.6rem] text-gray-500">@{character.owner_name}</div>
									
								</div>
							</div>
						</div>
					))}
				</div>
			) : (
				<TextStyleBodyDefaultMedium>This community is still without characters</TextStyleBodyDefaultMedium>
			)}
             */
           }
        </div>
    )
}